import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthTokenService } from './auth.service';

const canActivate: CanActivateFn = async (route, state) => {
  const auth = inject(AuthTokenService);
  const router = inject(Router);

  const isGuestPath = state.url.startsWith('/accesso');
  const user = auth.getUser();
  const isAuthCompleted = auth.isAuthCompleted();

  if (auth.logged.getValue() && auth.isAuthenticated() && user != null && isAuthCompleted) {
    const roles = user.roles;
    if (!roles.includes('ROLE_ADMINISTRATOR') && !roles.includes('ROLE_MOBILITY_MANAGER')) {
      await router.navigate([''], { replaceUrl: true });
      return false;
    }

    if (isGuestPath) {
      await router.navigate(['/home/collections']);
      return false;
    } else {
      return true;
    }
  } else {
    auth.signOut();
    if (isGuestPath) {
      return true;
    } else {
      auth.redirectUrl = state.url;
      await router.navigate(['/accesso/login'], { replaceUrl: true });
      return false;
    }
  }
};

export const canActivateAuthGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
