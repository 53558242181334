import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, } from 'rxjs/operators';
import { PaginatedList, POI, POIRating, RequestPaginatedList } from '../interfaces/collections';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root',
})
export class POIService {

  constructor(public apiService: ApiService) {}

  /**
   * [NEW API] Recupera una lista paginata di POIs, per cityCode
   */
  getPOIs(
    cityCode: string,
    paginated: RequestPaginatedList,
    filters?: string
  ): Observable<PaginatedList<POI>> {
    const turkraft_filters = filters ? '&filter='+filters : '';
    const paramsFilters = filters != null ? `?page=${paginated.page || 0}&size=${paginated.size || 10}${turkraft_filters}` : '';
    return this.apiService
      .get<PaginatedList<POI>>(
        `cities/${cityCode}/pois${paramsFilters}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * [NEW API] Recupera una lista paginata di POIs, per cityCode
   */
  getAllPOIs(
    cityCode: string
  ): Observable<POI[]> {
    return this.apiService
      .get<POI[]>(
        `cities/${cityCode}/pois/all`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * [NEW API] Recupera un POI tramite id
   *
   * @param poiId
   * @returns Observable<POI>
   */
  /* getPOI(poiId: number): Observable<POI> {
    return this.apiService
      .get<POI>(`pois/${poiId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }*/

  /**
   * [NEW API] Recupera i voti di un POI tramite id
   *
   * @param poiId
   * @returns Observable<POIRating[]>
   */
  getPOIRatings(poiId: number): Observable<POIRating[]> {
    return this.apiService
      .get<POIRating[]>(`pois/${poiId}/votes/all`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }



  /**
   * [NEW API] Crea un nuovo POI
   *
   * @param poi
   * @returns Observable<POI>
   */
  createPOI(poi: POI): Observable<POI> {
    return this.apiService
      .post<POI>('pois', poi)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * [NEW API] Aggiorna un POI
   *
   * @param poi
   * @returns Observable<POI>
   */
  updatePOI(poi: POI): Observable<POI> {
    if (poi == null || poi.id == null) {
      throw new Error('id poi mancante');
    }

    return this.apiService
      .put<POI>(
        `pois/${poi.id}`,
        poi
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * [NEW API] Rimuove un POI by id
   *
   * @param node
   * @returns Observable<boolean>
   */
  deletePOI(poiId: number): Observable<boolean> {
    if (!poiId) {
      throw new Error('id node mancante');
    }
    return this.apiService
      .delete<boolean>(`pois/${poiId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
