import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { PasswordModule } from 'primeng/password';
import { ModalChangePasswordComponent } from './modal-change-password/modal-change-password.component';
import { ModalLicenseStatusComponent } from './modal-license-status/modal-license-status.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { LanguageDropdownModule } from '../../modules/language-dropdown/language-dropdown.module';

@NgModule({
  declarations: [UserMenuComponent, ModalChangePasswordComponent, ModalLicenseStatusComponent],
  imports: [
    CommonModule,
    MenuModule,
    TranslateModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    OverlayPanelModule,
    AvatarModule,
    LanguageDropdownModule,
  ],
  exports: [UserMenuComponent, ModalChangePasswordComponent],
})
export class UserMenuModule {}
