/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';
import { RequestChangePassword } from 'src/app/shared/interfaces/auth';
import { MessageDto } from 'src/app/shared/interfaces/download-pdf-info';
import { ContextService } from 'src/app/shared/services/context.service';
import { UserService } from 'src/app/shared/services/user.service';

interface IForm {
  password: FormControl<string | undefined>;
  newPassword: FormControl<string | undefined>;
  confirmPassword: FormControl<string | undefined>;
}

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangePasswordComponent implements OnDestroy, OnInit {
  changePasswForm!: FormGroup<IForm>;
  passwErrorParams = UtilityHelper.passwordLengths;
  username!: string;
  keyguid!: string;
  errorPassword = '';
  errorConfirmPassword = '';
  errorNewPassword = '';

  hideCancelButton = false;

  private subs: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private contextService: ContextService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.hideCancelButton = this.config.data?.hideCancelButton;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  closeModal(): void {
    this.ref.close();
  }

  initForm(): void {
    this.changePasswForm = new FormGroup<IForm>(
      {
        password: new FormControl<string | undefined>(undefined, {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.minLength(this.passwErrorParams.minLength),
            Validators.maxLength(this.passwErrorParams.maxLength),
          ],
        }),
        newPassword: new FormControl<string | undefined>(undefined, {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(UtilityHelper.validatePassword)],
        }),
        confirmPassword: new FormControl<string | undefined>(undefined, {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(UtilityHelper.validatePassword)],
        }),
      },
      {
        validators: [this.checkEqualsPasswords, this.oldPasswDiffWithNew],
      }
    );

    const formChangeSub = this.changePasswForm.valueChanges.subscribe(() => {
      this.errorPassword = UtilityHelper.checkError('password', this.changePasswForm);
      this.errorConfirmPassword = UtilityHelper.checkError('confirmPassword', this.changePasswForm);
      this.errorNewPassword = UtilityHelper.checkError('newPassword', this.changePasswForm);

      if (this.changePasswForm.hasError('notDiff')) {
        this.errorNewPassword = 'server_errors.PASSWORD_REQUIRED_DIFF';
      }

      if (this.changePasswForm.hasError('notSame')) {
        this.errorConfirmPassword = 'server_errors.PASSWORD_NO_MATCH';
      }
    });

    this.subs.add(formChangeSub);
  }

  onConfirm(): void {
    const jwtBody = this.contextService.getUser();
    if (this.changePasswForm.invalid || jwtBody == null) {
      return;
    }
    const reqChange: RequestChangePassword = {
      password: this.changePasswForm.controls.password.value || '',
      newPassword: this.changePasswForm.controls.newPassword.value || '',
      confirmPassword: this.changePasswForm.controls.confirmPassword.value || '',
    };
    this.userService.changePassword(reqChange, jwtBody.userId).subscribe({
      next: (isUpd: MessageDto<boolean>) => {
        if (!isUpd.message) {
          this.changePasswForm.reset();
        }
        this.ref.close(isUpd.message);
      },
      error: () => {
        return false;
      },
    });
  }

  checkEqualsPasswords: ValidatorFn = (): ValidationErrors | null => {
    if (this.changePasswForm == null) return null;
    const pass = this.changePasswForm.controls.newPassword.value;
    const confirmPass = this.changePasswForm.controls.confirmPassword.value;
    if (!confirmPass) return null;
    return pass === confirmPass ? null : { notSame: true };
  };

  oldPasswDiffWithNew: ValidatorFn = (): ValidationErrors | null => {
    if (this.changePasswForm == null) return null;
    const passw = this.changePasswForm.controls.password.value;
    const newPassword = this.changePasswForm.controls.newPassword.value;
    if (!newPassword) return null;
    return passw !== newPassword ? null : { notDiff: true };
  };
}
