const collectionsSection = {
  label: 'commons.collections',
  icon: 'fas fa-layer-group',
  routerLink: ['/home/collections'],
};
const configSection = {
  label: 'commons.config_context',
  icon: 'fas fa-city',
  routerLink: ['/city-configuration/config'],
  items: [
    {
      label: 'admin.configuration.credits.header',
      routerLink: ['/city-configuration/credits'],
    },
    {
      label: 'admin.configuration.pois.header',
      routerLink: ['/city-configuration/pois'],
    },
    {
      label: 'commons.images',
      routerLink: ['/city-configuration/default-images'],
    },
  ],
};

const adminSection = {
  label: 'commons.dash_admin',
  icon: 'fas fa-cogs',
  routerLink: ['/admin'],
};

export const homeMobilityNavItems: any[] = [
  {
    items: [collectionsSection, configSection],
  },
];

export const homeAdminNavItems: any[] = [
  {
    items: [collectionsSection, configSection, adminSection],
  },
];
