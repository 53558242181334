import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CollectionLayoutService } from '../../service/collection.layout.service';

@Component({
  selector: 'collection-breadcrumb',
  templateUrl: './collection.breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionBreadcrumbComponent {
  items$ = this.collectionLayoutService.breadcrumbSource$;
  isMapEditor$ = this.collectionLayoutService.isMapEditor$;
  home = { icon: 'fas fa-home', routerLink: '/' };

  constructor(private collectionLayoutService: CollectionLayoutService) {}
}
