import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthTokenService } from './auth.service';

const canActivate: CanActivateFn = async () => {
  const auth = inject(AuthTokenService);
  const router = inject(Router);

  const user = auth.getUser();

  if (user != null) {
    const roles = user.roles;
    if (roles.includes('ROLE_ADMINISTRATOR')) {
      return true;
    }
  }

  await router.navigate([''], { replaceUrl: true });
  return false;
};

export const canActivateAdminGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);


