import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AppConfig } from '../interfaces/config.interface';

@Injectable()
export class AppConfigService {
  private appConfig?: AppConfig;

  constructor(private http: HttpClient) { }

  public get name(): string {
    return this.getConfig().name;
  }

  public get domain(): string {
    return this.getConfig().domain;
  }

  public get apiUrl(): string {
    return this.getConfig().api_url;
  }

  public get apiActuator(): string {
    return this.getConfig().api_actuator;
  }

  public get nominatimService(): string {
    return this.getConfig().nominatim_service;
  }

  async loadAppConfig(): Promise<void> {
    const config = await lastValueFrom(this.http.get('./assets/config.json'));
    this.appConfig = config as AppConfig;
  }

  getConfig(): AppConfig {
    return this.appConfig as AppConfig;
  }
}