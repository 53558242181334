import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { City, CityConfiguration, PaginatedList } from '../interfaces/collections';
import { MessageDto } from '../interfaces/download-pdf-info';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(public apiService: ApiService) {
  }

  getAllPaginated(page: number,
    size: number,
    order: string,): Observable<PaginatedList<City>> {
    return this.apiService.get<PaginatedList<City>>
    (`cities?page=${page}&size=${size}&sort=${order}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getByCityCode(cityCode: string): Observable<City> {
    return this.apiService.get<City>
    (`cities/${cityCode}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * [NEW API] Recupera una CityConfiguration tramite cityCode
   *
   * @param categoryId
   * @returns Observable<CityConfiguration>
   */
   getCityConfiguration(cityCode: string): Observable<CityConfiguration> {
    return this.apiService
      .get<CityConfiguration>(
        `cities/${cityCode}/configurations`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  createCity(city: City): Observable<City> {
    return this.apiService
      .post<City>('cities', city).pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteAllFromCity(cityCode: string): Observable<MessageDto<boolean>> {
    return this.apiService
      .delete<MessageDto<boolean>>(
        `cities/${cityCode}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

}
