<div class="grid grid-nogutter mt-2">
  <div class="col-12">
    <form [formGroup]="licenseForm">
      <!-- TYPE -->
      <div class="field grid">
        <label htmlFor="type" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.typeLicense' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <input
            pInputText
            class="w-full"
            id="type"
            placeholder="{{ 'admin.configuration.map-license.typeLicense' | translate }}"
            formControlName="type"
            type="text" />
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['type'] ? (formErrors['type'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- max map -->
      <div class="field grid">
        <label htmlFor="numberMaxMaps" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.numMaxMaps' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            formControlName="numberMaxMaps"
            id="numberMaxMaps"
            [min]="paramsError.minValue"
            [max]="paramsError.maxValue">
          </p-inputNumber>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['numberMaxMaps'] ? (formErrors['numberMaxMaps'] | translate : paramsError) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- numberMaxPoi -->
      <div class="field grid">
        <label htmlFor="numberMaxPoi" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.numMaxPoi' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            formControlName="numberMaxPoi"
            id="numberMaxPoi"
            [min]="paramsError.minValue"
            [max]="paramsError.maxValue">
          </p-inputNumber>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['numberMaxPoi'] ? (formErrors['numberMaxPoi'] | translate : paramsError) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- Max Versions -->
      <div class="field grid">
        <label htmlFor="numberMaxVersions" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.numMaxVersions' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            formControlName="numberMaxVersions"
            id="numberMaxVersions"
            [min]="paramsError.minValue"
            [max]="paramsError.maxValue">
          </p-inputNumber>
          <p class="text-sm text-red-500 mb-2">
            {{
              formErrors['numberMaxVersions'] ? (formErrors['numberMaxVersions'] | translate : paramsError) : '&nbsp;'
            }}
          </p>
        </div>
      </div>

      <!-- price_month -->
      <div class="field grid">
        <label htmlFor="priceForMonth" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.numMaxVersions' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-inputNumber
            min="0"
            mode="currency"
            currency="EUR"
            class="w-full"
            styleClass="w-full"
            formControlName="priceForMonth"
            id="priceForMonth">
          </p-inputNumber>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['priceForMonth'] ? (formErrors['priceForMonth'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>
    </form>
  </div>

  <div class="col-12 flex justify-content-end">
    <p-button pRipple [outlined]="true" severity="secondary" (click)="closeModal()">
      {{ 'commons.cancel' | translate }}
    </p-button>
    <p-button
      pRipple
      [label]="'commons.confirm' | translate"
      [disabled]="licenseForm && !licenseForm.valid"
      class="p-button-success ml-4"
      (click)="onConfirm()"></p-button>
  </div>
</div>
