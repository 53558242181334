import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { languageFlagCode } from './language-flag.enum';

@Component({
  selector: 'language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageDropdownComponent {

  @Input()
  wFull = false;

  public currentLang: string;
  public languageFlagCode = languageFlagCode;

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  get languageItems(): string[] {
    return environment.supportedLanguages;
  }

  setLanguage(evt: any): void {
    const language: string = evt.value;
    if (this.translate.currentLang == null) {
      return;
    }
    this.translate.use(language);
    // this.cd.detectChanges();
  }

  private getLanguageFlag(code: string): string {
    if (code === 'en') {
      return 'gb';
    }
    return code;
  }
}
