import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DialogService } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { Columns, License } from 'src/app/shared/interfaces/admin';
import { PaginatedList } from 'src/app/shared/interfaces/collections';
import { MessageDto } from 'src/app/shared/interfaces/download-pdf-info';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { LicenseService } from 'src/app/shared/services/license.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';
import { AddEditLicenseComponent } from '../add-edit-license/add-edit-license.component';

@Component({
  selector: 'app-license-management',
  templateUrl: './license-management.component.html',
  styleUrls: ['./license-management.component.scss'],
})
export class LicenseManagementComponent implements OnInit {
  licenseData: License[] = [];
  // table handle
  columns: Columns[] = [];
  first = 0;
  rows = 10;
  page = 0; // starts from page index 0
  loading = false; // spinner used for loading data of table
  totalRecords!: number; // total rows
  orderUserData!: string;
  ids: number[] = [];

  constructor(
    private translate: TranslateService,
    private licenseService: LicenseService,
    private notification: NotificationService,
    private configurationService: ConfigurationService,
    private confirmService: ConfirmService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private currencyPipe: CurrencyPipe
  ) {
    this.columns = [
      {
        field: 'type',
        header: 'admin.configuration.map-license.typeLicense',
      },
      {
        field: 'numberMaxMaps',
        header: 'admin.configuration.map-license.numMaxMaps',
      },
      {
        field: 'numberMaxPoi',
        header: 'admin.configuration.map-license.numMaxPoi',
      },
      {
        field: 'numberMaxVersions',
        header: 'admin.configuration.map-license.numMaxVersions',
      },
      {
        field: 'priceForMonth',
        header: 'admin.configuration.map-license.priceForMonth',
      },
    ];
  }

  ngOnInit(): void {
    this.getLicenseData();
    this.getListIdsDisableLicense();
  }

  // event lazy load
  lazyLoad(event: TableLazyLoadEvent): void {
    if (event.first != null && event.rows != null) {
      this.page = event.first / event.rows; // calculate rows
    }
    if (event.sortField != null && event.sortOrder != null) {
      this.orderUserData = event.sortOrder === 1 ? `${event.sortField},ASC` : `${event.sortField},DESC`;
    }

    this.getLicenseData(this.page, this.rows, this.orderUserData);
  }

  getListIdsDisableLicense(): void {
    this.configurationService.licenseIds().subscribe(data => (this.ids = data));
  }

  getLicenseData(page: number = 0, rows: number = 10, order: string = 'numberMaxMaps,numberMaxPoi,DESC'): void {
    this.loaderService.showLoader();
    this.licenseService.getLicensePage(page, rows, order).subscribe({
      next: (response: PaginatedList<License>) => {
        this.totalRecords = response.totalElements;
        this.rows = response.size;
        this.licenseData = response.content;
        this.loaderService.hideLoader();
      },
      error: err => {
        console.error(err);
        this.loaderService.hideLoader();
      },
    });
  }

  addLicense(): void {
    this.openModalLicense();
  }

  editLicense(license: License): void {
    this.openModalLicense(license.id);
  }

  exportExcel(): void {
    this.loaderService.showLoader();
    const headers = [
      this.translate.instant('excel.headers.licenseType'),
      this.translate.instant('admin.configuration.map-license.numMaxMaps'),
      this.translate.instant('admin.configuration.map-license.numMaxPoi'),
      this.translate.instant('admin.configuration.map-license.numMaxVersions'),
      this.translate.instant('admin.configuration.map-license.priceForMonth'),
    ];
    const data = this.licenseData.map(license => ({
      [headers[0]]: license.type,
      [headers[1]]: license.numberMaxMaps,
      [headers[2]]: license.numberMaxPoi,
      [headers[3]]: license.numberMaxVersions,
      [headers[4]]: license.priceForMonth,
    }));
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DatiAbbonamento');
    XLSX.writeFile(workbook, `${this.translate.instant('commons.abbonamentoFileName')}.xlsx`);
    this.loaderService.hideLoader();
  }

  exportPdf(): void {
    this.loaderService.showLoader();
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
    });

    doc.setFontSize(18);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    doc.text(this.translate.instant('commons.abbonamentoFileName'), 30, 35);

    const head = [
      [
        '#',
        this.translate.instant('excel.headers.licenseType'),
        this.translate.instant('admin.configuration.map-license.numMaxMaps'),
        this.translate.instant('admin.configuration.map-license.numMaxPoi'),
        this.translate.instant('admin.configuration.map-license.numMaxVersions'),
        this.translate.instant('admin.configuration.map-license.priceForMonth'),
      ],
    ];

    const body = this.licenseData.map((license, index) => [
      index + 1,
      license.type,
      license.numberMaxMaps,
      license.numberMaxPoi,
      license.numberMaxVersions,
      this.currencyPipe.transform(license.priceForMonth, 'EUR'),
    ]);
    autoTable(doc, { startY: 65, head, body });
    doc.save(`${this.translate.instant('commons.abbonamentoFileName')}.pdf`);

    this.loaderService.hideLoader();
  }

  async removeLicense(license: License): Promise<void> {
    const title: string = this.translate.instant('admin.configuration.map-license.deleteLicense');
    const message: string = this.translate.instant('admin.configuration.map-license.deleteLicenseQuestion', {
      name: license.type,
    });
    const action = await this.confirmService.showDialog(title, message);

    if (action !== 'confirm') {
      return;
    }

    this.licenseService.deleteLicense(license.id).subscribe({
      next: (response: MessageDto<boolean>) => {
        if (response.message === true) {
          this.getLicenseData();
          this.notification.success(
            this.translate.instant('admin.configuration.map-license.successDeletedLicense') as string
          );
        } else {
          console.error('not removed');
        }
      },
    });
  }

  private onConfirmSuccess(): void {
    this.getLicenseData();
    this.getListIdsDisableLicense();
  }

  private openModalLicense(licenseId?: number): void {
    const header =
      licenseId != null ? 'admin.configuration.map-license.editLicense' : 'admin.configuration.map-license.addLicense';
    const dialogRef = this.dialogService.open(AddEditLicenseComponent, {
      header: this.translate.instant(header),
      closable: true,
      contentStyle: { overflow: 'auto' },
      styleClass: 'responsive-dialog',
      baseZIndex: 100,
      data: {
        licenseId,
      },
    });

    const subDialog = dialogRef.onClose.subscribe((license: License) => {
      if (license) {
        this.onConfirmSuccess();
      }
      subDialog.unsubscribe();
    });
  }
}
