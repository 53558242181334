import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserConfigurationRoutingModule } from './user-configuration-routing.module';
import { UserConfigurationComponent } from './user-configuration/user-configuration.component';
import { ModalAddEditUserComponent } from './modal-add-edit-user/modal-add-edit-user.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    UserConfigurationComponent,
    ModalAddEditUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    DropdownModule,
    TranslateModule,
    TooltipModule,
    UserConfigurationRoutingModule
  ],
  providers: [MessageService]
})
export class UserConfigurationModule { }
