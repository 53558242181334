<div class="grid">
  <div class="col-12">
    <div class="card flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ 'admin.configuration.map-license.title' | translate }}</h5>
      <button
        pButton
        pRipple
        pTooltip="{{ 'admin.configuration.map-license.addLicense' | translate }}"
        tooltipPosition="bottom"
        [label]="'admin.configuration.map-license.addLicense' | translate"
        icon="fas fa-plus"
        (click)="addLicense()"></button>
    </div>
  </div>
</div>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-table
        #myTable
        [value]="licenseData"
        [resizableColumns]="true"
        [rows]="rows"
        [columns]="columns"
        [(first)]="first"
        [totalRecords]="totalRecords"
        [showCurrentPageReport]="true"
        [paginator]="true"
        [rowHover]="true"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        (onLazyLoad)="lazyLoad($event)"
        currentPageReportTemplate="{{
          'paginator.labelTablePagination' | translate : { last: totalRecords != null ? totalRecords : '0' }
        }}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <!--<button
              type="button"
              pButton
              pRipple
              icon="pi pi-file"
              (click)="myTable.exportCSV()"
              class="mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom"></button>-->
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-excel"
              (click)="exportExcel()"
              class="p-button-success mr-2"
              pTooltip="XLS"
              tooltipPosition="bottom"></button>
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-pdf"
              (click)="exportPdf()"
              class="p-button-warning mr-2"
              pTooltip="PDF"
              tooltipPosition="bottom"></button>
          </div>
        </ng-template>
        <!-- THEAD COLUMNS -->
        <ng-template pTemplate="header">
          <tr>
            <th scope="row">#</th>
            <th scope="row" [pSortableColumn]="col.field" *ngFor="let col of columns">
              {{ col.header | translate }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th scope="row">{{ 'commons.details' | translate }}</th>
          </tr>
        </ng-template>
        <!-- TBODY DATA -->
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
          <tr>
            <td>{{ ri + 1 }}</td>
            <td *ngFor="let col of columns">
              {{ col.field === 'priceForMonth' ? (rowData[col.field] | currency : 'EUR') : rowData[col.field] }}
            </td>
            <td>
              <p-button
                class="mr-2"
                icon="fas fa-trash"
                size="small"
                severity="danger"
                *ngIf="!ids.includes(rowData.id)"
                pTooltip="{{ 'admin.configuration.map-license.deleteLicense' | translate }}"
                (click)="removeLicense(rowData)"></p-button>
              <p-button
                icon="fas fa-edit"
                size="small"
                severity="secondary"
                pTooltip="{{ 'admin.configuration.map-license.editLicense' | translate }}"
                (click)="editLicense(rowData)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>