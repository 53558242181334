/* eslint-disable @typescript-eslint/member-ordering */
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subject, Subscription, combineLatest, tap } from 'rxjs';
import { Category, CollectionStatus, Distance, MapInfo } from 'src/app/shared/interfaces/collections';
import { UndoRedoState } from 'src/app/shared/modules/cytoscape/_interfaces/save-history';
import { MapButtonsEvents } from 'src/app/shared/modules/map-buttons/map-buttons.component';
import { BreadcrumbService } from './app.breadcrumb.service';

@Injectable({
  providedIn: 'root',
})
export class CollectionLayoutService implements OnDestroy {
  sidebarEvent = new EventEmitter<
    'categories' | 'distances' | 'icons' | 'city-pois' | 'editInfo' | 'manageVersions' | 'publishMap'
  >();
  itemClick = new EventEmitter<{
    type: 'category' | 'distance';
    item: Category | Distance;
  }>();
  mapButtonsClick = new EventEmitter<MapButtonsEvents>();
  mapInfo$ = new BehaviorSubject<MapInfo | undefined>(undefined);
  editingMode$ = new BehaviorSubject<boolean>(false);
  undoRedoState$ = new BehaviorSubject<UndoRedoState>({ isUndoDisabled: true, isRedoDisabled: true });

  breadcrumbSource$ = new Subject<MenuItem[]>();
  isMapEditor$ = new BehaviorSubject<boolean>(true);

  private subs = new Subscription();

  constructor(private breadcrumbService: BreadcrumbService, private translate: TranslateService) {
    const sub = combineLatest([this.breadcrumbService.breadcrumbSource$, this.mapInfo$])
      .pipe(
        tap(([breadCrumbSource, mapInfo]) => {
          let isMapEditor = false;
          const label = mapInfo?.mapData?.collection.name;
          const finalBreadCrumbSource: MenuItem[] = [];
          if (label) {
            finalBreadCrumbSource.push({
              label:
                mapInfo?.mapData?.collection.status === CollectionStatus.DRAFT
                  ? `${label} [${this.translate.instant('map_editor.status.DRAFT')}]`
                  : label,
              routerLink: '',
            });
          }
          breadCrumbSource.forEach(bc => {
            if (bc.label === 'MAP_EDITOR') {
              isMapEditor = true;
            } else {
              finalBreadCrumbSource.push(bc);
            }
          });

          this.isMapEditor$.next(isMapEditor);
          this.breadcrumbSource$.next(finalBreadCrumbSource);
        })
      )
      .subscribe();
    this.subs.add(sub);
  }

  resetService(): void {
    this.isMapEditor$.next(true);
    this.mapInfo$.next(undefined);
    this.editingMode$.next(false);
    this.undoRedoState$.next({ isUndoDisabled: true, isRedoDisabled: true });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
