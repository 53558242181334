export interface ResponseAuth {
  token: string;
  username: string;
  email: string;
  roles: string[];
  tenant_id: string[];
  otp: boolean;
  userId: number;
}

export interface RequestChangePassword {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export type RequestResetPassword = Pick<RequestChangePassword, 'newPassword' | 'confirmPassword'>;

export interface UserConfig extends User {
  role: string;
  city: string;
}

export interface User {
  id?: number;
  email: string;
  username: string;
  password?: string;
  lastAccessTime: string;
  active: boolean;
  keyguid?: string;
  keyguidExpiration?: string;
  userCityRoles: UserCityRoles[];
  opt?: boolean;
}

export interface UserCityRoles {
  userId?: number;
  cityId: string;
  roleId: number;
  city?: City;
  role?: Role;
  user?: User;
}

export interface City {
  name: string;
  cityCode: string;
}

export interface Role {
  id: number;
  role: RoleEnum;
}

export enum RoleEnum {
  ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
  ROLE_MOBILITY_MANAGER = 'ROLE_MOBILITY_MANAGER',
}


export interface ResetPasswordDto {
  username: string;
  keyguid: string;
}

export interface ForgotPasswordDto {
  username: string;
  email: string;
  lang: string;
}