import { DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';

// Import containers

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MessageService } from 'primeng/api';
import { DialogModule as DialogModuleNg } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { AppLayoutModule } from './layout/app.layout.module';
import { LicenseConfigurationModule } from './modules/admin/_modules/license-configuration/license-configuration.module';
import { UserConfigurationModule } from './modules/admin/_modules/user-configuration/user-configuration.module';
import { UserMenuModule } from './shared/components/user-menu/user-menu.module';
import { DbTranslationModule } from './shared/directives/db-translation/db-translation.module';
import { appInitializerFn } from './shared/helpers/utility.helper';
import { DialogModule } from './shared/modules/dialog/dialog.module';
import { ModalSelectContextModule } from './shared/modules/modal-select-context/modal-select-context.module';
import { authInterceptorProviders } from './shared/security/interceptor/auth.interceptor';
import { ErrorInterceptor } from './shared/security/interceptor/error.interceptor';
import { AppConfigService } from './shared/services/app-config.service';
import { CookieService } from './shared/services/cookie.service';
import { CustomRouteStrategy } from './shared/services/custom-route-strategy';

// AoT requires an exported function for factories
export const HttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(CookieService.cookieConfig),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
    FormsModule,
    DbTranslationModule,
    MenuModule,
    DialogModuleNg,
    UserConfigurationModule,
    LicenseConfigurationModule,
    UserMenuModule,
    NgScrollbarModule,
    DropdownModule,
    ModalSelectContextModule,
    ToastModule,
    AppLayoutModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    Title,
    authInterceptorProviders,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteStrategy },
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    MessageService,
    DialogService,
    DatePipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
