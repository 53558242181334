import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicenseConfigurationRoutingModule } from './license-configuration-routing.module';
import { AddEditCityComponent } from './pages/add-edit-city/add-edit-city.component';
import { AddEditLicenseComponent } from './pages/add-edit-license/add-edit-license.component';
import { CityLicenseManagementComponent } from './pages/city-license-management/city-license-management.component';
import { LicenseManagementComponent } from './pages/license-management/license-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    AddEditCityComponent,
    AddEditLicenseComponent,
    CityLicenseManagementComponent,
    LicenseManagementComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TranslateModule,
    TooltipModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    LicenseConfigurationRoutingModule,
  ],
  providers: [MessageService, CurrencyPipe],
})
export class LicenseConfigurationModule {}
