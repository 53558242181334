import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaType } from '../enums/media-type.enum';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private defaultHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(public http: HttpClient, public confService: AppConfigService) { }

  public get apiUrl(): string {
    return this.confService.apiUrl;
  }

  @Injectable({
    providedIn: 'root',
  })


  get<T>(path: string, customOptions?: { params?: HttpParams }, url?: string): Observable<T> {
    if (!url) {
      url = this.apiUrl;
    }
    const options = {
      headers: this.defaultHeaders,
      params: customOptions != null ? customOptions.params : undefined,
    };
    return this.http.get<T>(`${url}${path}`, options);
  }

  post<T>(
    path: string,
    body: any,
    contentType?: MediaType,
    responseType?: string
  ): Observable<T> {
    const options = {
      headers: this.defaultHeaders,
    };
    if (contentType != null) {
      options.headers = this.defaultHeaders.set('Content-Type', contentType);
    }
    if (responseType != null) {
      (options as any).responseType = responseType;
    }
    return this.http.post<T>(`${this.apiUrl}${path}`, body, options);
  }

  put<T>(path: string, body: any, contentType?: MediaType): Observable<T> {
    const options = {
      headers: this.defaultHeaders,
    };
    if (contentType != null) {
      options.headers = this.defaultHeaders.set('Content-Type', contentType);
    }
    return this.http.put<T>(`${this.apiUrl}${path}`, body, options);
  }

  delete<T>(path: string, contentType?: MediaType, url?: string): Observable<T> {
    if (!url) {
      url = this.apiUrl;
    }
    const options = {
      headers: this.defaultHeaders,
    };
    if (contentType != null) {
      options.headers = this.defaultHeaders.set('Content-Type', contentType);
    }
    return this.http.delete<T>(`${url}${path}`, options);
  }
}
