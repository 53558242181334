/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  private interval?: any;

  constructor(
    private readonly zone: NgZone,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  info(message: string, duration?: number): void {
    this.show(message, 'info', duration);
  }

  success(message: string, duration?: number): void {
    this.show(message, 'success', duration);
  }

  warning(message: string, duration?: number): void {
    this.show(message, 'warn', duration);
  }

  error(message: string, duration = 5000): void {
    this.show(message, 'error', duration);
  }

  clear(): void {
    this.messageService.clear();
    clearTimeout(this.interval);
  }

  private show(
    detail: string,
    severity: 'success' | 'warn' | 'info' | 'error',
    duration: number = 2000,
    closable = true
  ): void {
    this.zone.run(() => {
      clearTimeout(this.interval);

      this.messageService.add({
        severity,
        summary: this.translate.instant(`notification.${severity}`),
        detail,
        closable,
      });

      this.interval = setTimeout(() => {
        this.messageService.clear();
      }, duration);
    });
  }
}
