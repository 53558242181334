<p-button
  *ngIf="(editingMode$ | async) === false"
  icon="fas fa-drafting-compass"
  severity="warning"
  (click)="onClickButtons('edit')"
  pTooltip="{{ 'commons.edit' | translate }}"
  tooltipPosition="bottom"></p-button>

<p-button
  *ngIf="editingMode$ | async"
  [disabled]="(undoRedoState$ | async).isUndoDisabled"
  [text]="true"
  [plain]="true"
  icon="fas fa-undo"
  class="mr-2"
  (click)="onClickButtons('undo')"
  pTooltip="{{ 'map_editor.undo' | translate }}"
  tooltipPosition="bottom"></p-button>

<p-button
  *ngIf="editingMode$ | async"
  [disabled]="(undoRedoState$ | async).isRedoDisabled"
  [text]="true"
  [plain]="true"
  icon="fas fa-redo"
  (click)="onClickButtons('redo')"
  pTooltip="{{ 'map_editor.redo' | translate }}"
  tooltipPosition="bottom"></p-button>

<div *ngIf="editingMode$ | async" class="v-line mx-2"></div>

<p-button
  *ngIf="editingMode$ | async"
  severity="danger"
  icon="fas fa-times-circle"
  class="mr-2"
  (click)="onClickButtons('cancel')"
  pTooltip="{{ 'map_editor.cancel_all' | translate }}"
  tooltipPosition="bottom"></p-button>

<p-button
  *ngIf="editingMode$ | async"
  [disabled]="(undoRedoState$ | async).isUndoDisabled"
  icon="fas fa-save"
  (click)="onClickButtons('confirm')"
  pTooltip="{{ 'commons.save' | translate }}"
  tooltipPosition="bottom"></p-button>

<div class="v-line mx-2"></div>

<p-overlayPanel #op styleClass="overlay-download-collection">
  <div class="flex flex-column">
    <p-button
      [text]="true"
      [plain]="true"
      icon="fas fa-file-image"
      (click)="this.onClickButtons('downloadPng')"
      [label]="'map_editor.download_png' | translate"
      [pTooltip]="'map_editor.download_png' | translate"
      tooltipPosition="bottom"></p-button>

    <p-button
      [text]="true"
      [plain]="true"
      icon="fas fa-file-pdf"
      (click)="this.onClickButtons('downloadPdf')"
      [label]="'map_editor.download_pdf' | translate"
      [pTooltip]="'map_editor.download_pdf' | translate"
      tooltipPosition="bottom"></p-button>

    <p-button
      [text]="true"
      [plain]="true"
      icon="fas fa-file-code"
      (click)="this.onClickButtons('downloadSvg')"
      [label]="'map_editor.download_svg' | translate"
      [pTooltip]="'map_editor.download_svg' | translate"
      tooltipPosition="bottom"></p-button>
  </div>
</p-overlayPanel>

<p-button
  [text]="true"
  [plain]="true"
  icon="fas fa-download"
  (click)="op.toggle($event)"
  pTooltip="{{ 'map_editor.download' | translate }}"
  tooltipPosition="bottom"></p-button>

<p-button
  [text]="true"
  [plain]="true"
  icon="fas fa-expand-arrows-alt"
  (click)="onClickButtons('fullscreen')"
  pTooltip="{{ 'map_editor.fullscreen' | translate }}"
  tooltipPosition="bottom"></p-button>
