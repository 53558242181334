/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerError } from '../../interfaces/server-error';

export const AUTH_ERROR = 'AUTH_ERROR';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthTokenService,
    private router: Router,
    private notification: NotificationService,
    private translate: TranslateService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let notified = false;
        if ([400].includes(err.status)) {
          notified = this.notifyError(err.error);
        }
        else if ([401].includes(err.status)) {
          this.auth.signOut();
          this.router
            .navigate(['/accesso/login'], { replaceUrl: true })
            .finally(() => {
              if (err.error.messageError === 'ACCOUNT_LOCK' || err.error.messageError === 'ERROR_BAD_CREDENTIAL_TOO_MANY_ATTEMPT') {
                notified = this.notifyError(err.error);
                // return throwError(() => AUTH_ERROR);
              } else {
                this.notification.error(this.translate.instant('commons.not_authorized') as string);
                notified = true;
              }
            });
        } else if ([422].includes(err.status)) {
          notified = this.notifyError(err.error);
        } else if ([500].includes(err.status)) {
          notified = this.notifyError(err.error);
          this.router.navigate([''], { replaceUrl: true }).finally(() => { });
        } else if ([404].includes(err.status)) {
          notified = this.notifyError(err.error);
        } else if ([403].includes(err.status)) {
          notified = this.notifyError(err.error);
          this.router.navigate(['/'], { replaceUrl: true }).finally(() => { });
        } else if ([0].includes(err.status)) {
          /* this.notification.error(
            this.translate.instant('commons.server_error') as string
          );*/
          if (!request.url.includes('maps/routing/plan')) {
            notified = true;
            this.router.navigate(['/public/maintenance'], { replaceUrl: true }).finally(() => {});
          }
        }

        let errorMessage: any = '';
        if (err.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = err.error.message;
        } else {
          // Get server-side error Type 2
          errorMessage = err;
        }

        const resultError = notified ? {} : errorMessage;
        return throwError(() => resultError);
      })
    );
  }

  /**
   * Se si tratta di un Errore ricevuto dal server, lo notifica con la rispettiva traduzione dall'ENUM
   *
   * @param serverError
   * @returns
   */
  private notifyError(serverError: ServerError): boolean {
    if (serverError != null && !!serverError.messageError) {
      this.notification.error(
        this.translate.instant(`server_errors.${serverError.messageError}`)
      );
      return true;
    }
    return false;
  }
}
