/* eslint-disable max-len */
import { HttpParams } from '@angular/common/http';
import { SortFilter } from '../components/find-sort/find-sort.component';
import { RequestPaginatedList } from '../interfaces/collections';

export class HttpHelper {
  /**
   * Crea gli HttpParams per una richiesta GET di tipo PaginatedList
   */
  public static getHttpParamsFromPaginatedRequest(
    paginatedRequest: RequestPaginatedList
  ): HttpParams {
    let httpParams = new HttpParams();
    if (paginatedRequest != null) {
      const entries = Object.entries(paginatedRequest);
      if (entries != null && entries.length > 0) {
        entries.forEach((value) => {
          const key = value[0];
          const val = value[1];
          if (key === 'sort' && val != null) {
            const sort = `${val.field},${val.direction}`;
            httpParams = httpParams.set('sort', sort);
          } else {
            httpParams = httpParams.set(key, `${val}`);
          }
        });
      }
    }
    return httpParams;
  }

  public static springFilterLike(sortFilter: SortFilter): string {
      let strFilter = '';
      if (sortFilter.field != null) {
        strFilter += `${sortFilter.field}~'*${sortFilter.query}*'`;
      }
      return strFilter;
  }

  public static springFilterEquals(sortFilter: SortFilter): string {
    let strFilter = '';
    if (sortFilter.field != null) {
      strFilter += `${sortFilter.field}~'${sortFilter.query}'`;
    }
    return strFilter;
}

}