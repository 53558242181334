import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../service/app.layout.service';
import { ModalSelectContextService } from 'src/app/shared/modules/modal-select-context/modal-select-context.service';
import { City } from 'src/app/shared/interfaces/collections';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalChangePasswordComponent } from 'src/app/shared/components/user-menu/modal-change-password/modal-change-password.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ModalLicenseStatusComponent } from 'src/app/shared/components/user-menu/modal-license-status/modal-license-status.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;
  items: MenuItem[] = [];

  constructor(
    public layoutService: LayoutService,
    private modalContext: ModalSelectContextService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private notification: NotificationService,
    private router: Router
  ) {}

  public openSelectContextModal(): void {
    const subDialog = this.modalContext.openModal(false).subscribe((city?: City) => {
      if (city) {
        this.onConfirmSelectContext().finally(() => {});
      }
      subDialog.unsubscribe();
    });
  }

  public openModalChangePassword(): void {
    const dialogRef = this.dialogService.open(ModalChangePasswordComponent, {
      closable: true,
      header: this.translate.instant('commons.changePassw'),
      contentStyle: { overflow: 'auto' },
      styleClass: 'responsive-dialog',
      baseZIndex: 100,
    });

    const subDialog = dialogRef.onClose.subscribe((isUpdated: boolean) => {
      if (isUpdated === true) {
        this.notification.success(this.translate.instant('commons.successResetPassw') as string);
      } else if (isUpdated === false) {
        this.notification.error(this.translate.instant('commons.error') as string);
      }
      subDialog.unsubscribe();
    });
  }

  public openModalLicenseStatus(): void {
    this.dialogService.open(ModalLicenseStatusComponent, {
      closable: true,
      header: this.translate.instant('license_status.title'),
      contentStyle: { overflow: 'auto' },
      styleClass: 'responsive-dialog',
      baseZIndex: 100,
    });
  }

  private async onConfirmSelectContext(): Promise<void> {
    await this.router.navigate(['/home/collections'], { replaceUrl: true });
  }
}
