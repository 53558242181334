import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbService } from '../../service/app.breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBreadcrumbComponent {
  items$ = this.breadcrumbService.breadcrumbSource$;
  home = { icon: 'fas fa-home', routerLink: '/' };

  constructor(private breadcrumbService: BreadcrumbService) {}
}
