<div class="grid">
  <div class="col-12">
    <div class="card flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ 'admin.configuration.map-license.list-city-license' | translate }}</h5>
      <button
        pButton
        pRipple
        tooltipPosition="bottom"
        [label]="'admin.configuration.map-license.add-city-license' | translate"
        icon="fas fa-plus"
        (click)="addCity()"
        pTooltip="{{ 'admin.configuration.map-license.add-city-license' | translate }}"></button>
    </div>
  </div>
</div>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-table
        #myTable
        [value]="cityData"
        [resizableColumns]="true"
        [rows]="rows"
        [columns]="columns"
        [(first)]="first"
        [totalRecords]="totalRecords"
        [showCurrentPageReport]="true"
        [paginator]="true"
        [rowHover]="true"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        (onLazyLoad)="lazyLoad($event)"
        currentPageReportTemplate="{{
          'paginator.labelTablePagination' | translate : { last: totalRecords != null ? totalRecords : '0' }
        }}">
        <ng-template pTemplate="caption">
          <div class="flex">
            <!--<button
              type="button"
              pButton
              pRipple
              icon="pi pi-file"
              (click)="myTable.exportCSV()"
              class="mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom"></button>-->
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-excel"
              (click)="exportExcel()"
              class="p-button-success mr-2"
              pTooltip="XLS"
              tooltipPosition="bottom"></button>
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-pdf"
              (click)="exportPdf()"
              class="p-button-warning mr-2"
              pTooltip="PDF"
              tooltipPosition="bottom"></button>
          </div>
        </ng-template>
        <!-- THEAD COLUMNS -->
        <ng-template pTemplate="header">
          <tr>
            <th scope="row">#</th>
            <th
              scope="row"
              [pSortableColumn]="col.subfield ? col.field + '.' + col.subfield : col.field"
              *ngFor="let col of columns">
              {{ col.header | translate }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th scope="row">{{ 'commons.details' | translate }}</th>
          </tr>
        </ng-template>
        <!-- TBODY DATA -->
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
          <tr>
            <td>{{ ri + 1 }}</td>
            <td
              *ngFor="let col of columns"
              [ngStyle]="{
                color: col.field === 'endSubscription' && licenseExpired(rowData[col.field]) ? 'var(--red-500)' : ''
              }">
              <span *ngIf="col.field !== 'active'; else activeIcon">
                {{
                  col.subfield && rowData[col.field] !== null
                    ? rowData[col.field][col.subfield]
                    : rowData[col.field] !== null
                    ? rowData[col.field]
                    : ''
                }}
              </span>
              <ng-template #activeIcon>
                <i
                  [ngClass]="
                    rowData[col.field] ? 'active-icon fas fa-check-circle' : 'deactive-icon fas fa-times-circle'
                  "></i>
              </ng-template>
            </td>
            <td>
              <p-button
                class="mr-2"
                icon="fas fa-trash"
                size="small"
                severity="danger"
                *ngIf="!!currentContext && currentContext.cityCode !== rowData.city?.cityCode"
                pTooltip="{{ 'admin.configuration.map-license.delete-city-license' | translate }}"
                (click)="removeCity(rowData)"></p-button>
              <p-button
                icon="fas fa-edit"
                size="small"
                severity="secondary"
                pTooltip="{{ 'admin.configuration.map-license.edit-city-license' | translate }}"
                (click)="editCity(rowData)"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
