import { DbTranslation } from '../interfaces/collections';

export class DbTranslationHelper {
    public static translation(translations: DbTranslation[], field: 'label' | 'description' | 'url', codeLang: string): string {
        let value = '';
        if (!!field && !!translations && translations.length > 0) {
            const currentLang = this.getLanguageCodeForDb(codeLang);
            const currentTranslation = translations.find((val) => val.lang === currentLang);
            if (currentTranslation != null && currentTranslation[field] != null) {
                value = currentTranslation[field] || '';
            } else {
                value = '';
            }
        }
        return value;
    }

    public static getLanguageCodeForDb(code: string): string {
        if (code === 'en') {
            return 'gb';
        }
        return code;
    }

    public static getLanguageFromDbCode(code: string): string {
        if (code === 'gb') {
            return 'en';
        }
        return code;
    }
}