import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { License } from '../interfaces/admin';
import { PaginatedList } from '../interfaces/collections';
import { MessageDto } from '../interfaces/download-pdf-info';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(public apiService: ApiService) { }

  /**
   * [NEW API] Recupera una lista paginata di Icon, per collection id
   */
  getLicense(
  ): Observable<License[]> {
    return this.apiService
      .get<License[]>('licenses/all')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getLicensePage(page: number,
    size: number,
    order: string
  ): Observable<PaginatedList<License>> {
    return this.apiService
      .get<PaginatedList<License>>(`licenses?page=${page}&size=${size}&sort=${order}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getLicenseById(id: number
  ): Observable<License> {
    return this.apiService
      .get<License>(`licenses/${id}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateLicense(license: License, id: number): Observable<License> {
    return this.apiService.put<License>(`licenses/${id}`, license).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveLicense(license: License): Observable<License> {
    return this.apiService.post<License>('licenses', license).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteLicense(id: number): Observable<MessageDto<boolean>> {
    return this.apiService.delete<MessageDto<boolean>>(`licenses/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
