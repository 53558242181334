import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { Role } from '../interfaces/auth';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(public apiService: ApiService, private translate: TranslateService) {
  }

  getAllRoles(): Observable<Role[]> {
    return this.apiService.get<Role[]>('roles/all').pipe(
      map((data: Role[]) => {
        return data;
      })
    );
  }

}
