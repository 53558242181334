<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>

  <div class="layout-sidebar">
    <collection-sidebar></collection-sidebar>
  </div>
  <div class="layout-main-container">
    <collection-breadcrumb></collection-breadcrumb>
    <div class="layout-main mt-4">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <div class="layout-mask"></div>
</div>
