import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { City, CityConfiguration } from '../../interfaces/collections';
import { CityService } from '../../services/city.service';
import { ContextService } from '../../services/context.service';
import { LoaderService } from '../../services/loader.service';
import { UserService } from '../../services/user.service';
import { ModalSelectContextComponent } from './modal-select-context.component';

interface ILoadContext {
  cities: City[];
  selectedCityCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalSelectContextService {
  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private userService: UserService,
    private contextService: ContextService,
    private loaderService: LoaderService,
    private cityService: CityService
  ) {}

  openModal(hideCancelButton = true): Observable<City | undefined> {
    this.loaderService.showLoader();
    return this.loadContexts().pipe(
      switchMap((r: ILoadContext) => {
        this.loaderService.hideLoader();
        return this.dialogService.open(ModalSelectContextComponent, {
          closable: false,
          header: this.translate.instant('admin.select_city'),
          contentStyle: { overflow: 'hidden' },
          baseZIndex: 100,
          data: {
            hideCancelButton,
            cities: r.cities,
            selectedCityCode: r.selectedCityCode,
          },
        }).onClose as Observable<City>;
      }),
      switchMap((city: City) => {
        this.loaderService.showLoader();
        if (city && city.cityCode) {
          return this.cityService.getCityConfiguration(city.cityCode).pipe(
            map(config => {
              return {
                city,
                config,
              };
            })
          );
        }
        return of({
          city,
          config: undefined,
        });
      }),
      map((result: { city?: City; config?: CityConfiguration }) => {
        if (result && result.city && result.config) {
          this.contextService.setCurrentContext(result.city, result.config);
        } else {
          this.loaderService.hideLoader();
        }
        return result?.city;
      }),
      catchError(err => {
        console.error(err);
        this.loaderService.hideLoader();
        throw err;
      })
    );
  }

  private loadContexts(): Observable<ILoadContext> {
    const userId = this.contextService.getUser()?.userId;

    const result: ILoadContext = {
      cities: [],
      selectedCityCode: undefined,
    };

    if (userId == null) {
      return of(result);
    }

    return this.userService.getUserCities(userId).pipe(
      map(cts => {
        if (cts?.length > 0) {
          result.cities = cts.sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          // Seleziono il contesto attuale, oppure il primo della lista
          const currentContext = this.contextService.currentContext.getValue();
          if (currentContext) {
            result.selectedCityCode = currentContext.cityCode;
          } else {
            result.selectedCityCode = result.cities[0].cityCode;
          }
        } else {
          result.cities = [];
        }

        return result;
      })
    );
  }
}
