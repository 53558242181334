/* eslint-disable max-len */

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AppConfigService } from '../services/app-config.service';

export const appInitializerFn = (appConfig: AppConfigService) => {
  return (): Promise<void> => {
    return appConfig.loadAppConfig();
  };
};

export class UtilityHelper {
  public static validateNumber = /^[0-9]*$/;
  public static validateCoordinate = /^(\-?\d+(\.\d+)?)$/;
  public static validatePhone = /^[0-9,]{0,255}$/;
  public static validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static validateUrl = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  public static validateUrlImg =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?.\.(png|jpeg|jpg|svg)$/;
  public static passwordLengths = {
    minLength: 8,
    maxLength: 50,
  };
  public static usernameLengths = {
    minLength: 6,
    maxLength: 50,
  };
  /**
   * ^                      # start of the string
   * (?=.*[0-9])            # a digit must occur at least once
   * (?=.*[a-z])            # a lower case letter must occur at least once
   * (?=.*[A-Z])            # an upper case letter must occur at least once
   * (?=.*[!@#$%^&*()\-_+]) # a special character must occur at least once
   * (?=\S+$)               # no whitespace allowed in the entire string
   * .{8,}                  # at least 8 character password, both inclusive
   * $                      # end of the string
   */
  public static validatePassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+])(?=\S+$).{8,}$/;

  public static minLenght3 = /^.{3,}$/;

  public static validatorPatternNotRequired(exp: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || !control.value) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const validation = exp.test(control.value);
      return !validation ? { snakeCase: { value: control.value } } : null;
    };
  }

  public static checkError(nomeCampo: string, formGroup: FormGroup | undefined): string {
    if (formGroup == null || formGroup.get(nomeCampo) == null) {
      return '';
    }

    if (formGroup.get(nomeCampo)?.hasError('required')) {
      return 'validators.required';
    }
    if (formGroup.get(nomeCampo)?.hasError('minlength')) {
      return 'validators.min_length';
    }
    if (formGroup.get(nomeCampo)?.hasError('maxlength')) {
      return 'validators.max_length';
    }
    if (formGroup.get(nomeCampo)?.hasError('min') || formGroup.get(nomeCampo)?.hasError('max')) {
      return 'validators.range_value';
    }
    if (formGroup.get(nomeCampo)?.hasError('pattern')) {
      return 'validators.pattern';
    }
    if (formGroup.get(nomeCampo)?.hasError('email')) {
      return 'validators.emailNotValid';
    }
    return '';
  }
}
