import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ForgotPasswordDto, RequestChangePassword, RequestResetPassword, ResetPasswordDto, ResponseAuth, Role, User} from '../interfaces/auth';
import {TranslateService} from '@ngx-translate/core';
import {MessageDto} from '../interfaces/download-pdf-info';
import { City, PaginatedList } from '../interfaces/collections';

export enum EAuthResponse {
  OK = 1,
  ERROR_SEND_EMAIL = -1,
  ERROR_EXPIRED_KEY_GUID = -2,
  ERROR_INVALID_KEY_GUID = -3,
  PASSWORD_NO_MATCH = -4,
  ERROR_TENANT_ID = -5,
  RESOURCE_FORBIDDEN = -6,
  ERROR_BAD_CREDENTIAL=-7
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public apiService: ApiService, private translate: TranslateService) {
  }


  /** *************** AUTHENTICATION API: login, forgotPassword, emailRetrievePassword  ***********************/
  login(username: string, password: string): Observable<ResponseAuth> {
    return this.apiService.post<ResponseAuth>(`${environment.auth_service}signin`, {
      username,
      password
    }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  logout(): Observable<MessageDto<boolean>> {
    return this.apiService.post<MessageDto<boolean>>(`${environment.auth_service}logout`, {
    }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  forgotPassword(forgotPassw: ForgotPasswordDto): Observable<MessageDto<EAuthResponse>> {
    forgotPassw.lang = this.translate.currentLang;
    return this.apiService.post<MessageDto<EAuthResponse>>
    (`${environment.auth_service}forgot-password`, forgotPassw).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkValidityKeyGuid(resetPassw: ResetPasswordDto): Observable<MessageDto<EAuthResponse>> {
    return this.apiService.post<MessageDto<EAuthResponse>>
    (`${environment.auth_service}check-reset-password-email`, resetPassw).pipe(
      map((data) => {
        return data;
      })
    );
  }

  resetPasswordAfterMail(body: RequestResetPassword, keyguid: string): Observable<MessageDto<EAuthResponse>> {
    return this.apiService.post<MessageDto<EAuthResponse>>(`${environment.auth_service}reset-password/${keyguid}`, body).pipe(
      map((data) => {
        return data;
      })
    );
  }


  /** *************** UTILITY USER API: changePassword, findByUsername, getAll, getById  ***********************/
  changePassword(body: RequestChangePassword, userId: number): Observable<MessageDto<boolean>> {
    return this.apiService.put<MessageDto<boolean>>(`users/change-password/${userId}`, body).pipe(
      map((data) => {
        return data;
      })
    );
  }

  findByUsername(username: string): Observable<User> {
    return this.apiService.get<User>(`users?username=${username}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllUsers(page: number,
    size: number,
    order: string): Observable<PaginatedList<User>> {
    return this.apiService.get<PaginatedList<User>>(`users?page=${page}&size=${size}&sort=${order}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getByUserById(id: number): Observable<User> {
    return this.apiService.get<User>(`users/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getRoleByUserById(id: number): Observable<Role> {
    return this.apiService.get<Role>(`users/${id}/roles`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateUser(user: User): Observable<User> {
    if (user == null || user.id == null) {
      throw new Error('id user mancante');
    }
    return this.apiService.put<User>(`users/${user.id}?lang=${this.translate.currentLang}`, user).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveUser(body: User): Observable<User> {
    return this.apiService.post<User>(`users?lang=${this.translate.currentLang}`, body).pipe(
      map((data) => {
        return data;
      })
    );
  }

  delete(userId: number): Observable<boolean> {
    return this.apiService.delete<boolean>(`users/${userId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUserCities(idUser: number): Observable<City[]> {
    return this.apiService.get<City[]>(`users/${idUser}/cities/all`).pipe(
      map((data) => {
        return data;
      })
    );
  }

}
