import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ResponseAuth } from '../interfaces/auth';
import { City, CityConfiguration } from '../interfaces/collections';
import { AuthTokenService } from '../security/services/auth.service';
import { MetroMinutoService } from './metrominuto.service';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  public currentContext: BehaviorSubject<City | null> =
    new BehaviorSubject<City | null>(null);

  constructor(
    private metroMinutoService: MetroMinutoService,
    private authService: AuthTokenService
  ) {
    const storedContext = this.authService.getContext();
    this.currentContext.next(storedContext);
  }

  get cities(): BehaviorSubject<City[]> {
    return this.metroMinutoService.cities;
  }

  get redirectUrl(): string | undefined {
    return this.authService.redirectUrl;
  }

  set redirectUrl(url: string | undefined) {
    this.authService.redirectUrl = url;
  }

  public getUser(): ResponseAuth | null {
    return this.authService.getUser();
  }

  public getRoles(): string[] {
    return this.authService.getRoles();
  }

  public isContextExpired(): boolean {
    const expireDate = this.authService.getEndSubscription();
    const now = new Date();
    return now.getTime() > expireDate.getTime();
  }

  public isAdmin(): boolean {
    const roles = this.getRoles();
    return roles.length !== 0 && roles.includes('ROLE_ADMINISTRATOR');
  }

  public hasMultipleTenants(): boolean {
    const tenants = this.authService.getTenant();
    return tenants != null && tenants.length > 1;
  }

  public signIn(data: ResponseAuth): void {
    this.authService.signIn(data);
  }

  public completeSignIn(): void {
    this.authService.completeSignIn();
  }

  public signOut(): void {
    this.currentContext.next(null);
    return this.authService.signOut();
  }

  public setCurrentContext(city: City, config: CityConfiguration): void {
    const endSubscription = moment(config.endSubscription).toDate();
    this.authService.setContext(city, endSubscription);
    this.currentContext.next(city);
  }
}
