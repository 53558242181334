<div class="grid grid-nogutter mt-2">
  <div class="col-12">
    <form *ngIf="cityForm" [formGroup]="cityForm">
      <!-- cityCode -->
      <div class="field grid">
        <label htmlFor="cityCode" class="col-12 mb-2 md:col-2 md:mb-0">City-Code</label>
        <div class="col-12 md:col-10">
          <input
            pInputText
            class="w-full"
            id="cityCode"
            maxlength="6"
            placeholder="{{ 'commons.name' | translate }}"
            formControlName="cityCode"
            type="text" />
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['cityCode'] ? (formErrors['cityCode'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- name -->
      <div class="field grid">
        <label htmlFor="name" class="col-12 mb-2 md:col-2 md:mb-0">{{ 'commons.name' | translate }}</label>
        <div class="col-12 md:col-10">
          <input
            pInputText
            class="w-full"
            id="name"
            placeholder="{{ 'commons.name' | translate }}"
            formControlName="name"
            type="text" />
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['name'] ? (formErrors['name'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- License -->
      <div class="field grid" *ngIf="licenseList && licenseList.length > 0">
        <label htmlFor="license" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.license' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-dropdown
            class="w-full"
            styleClass="w-full"
            [options]="licenseList"
            formControlName="license"
            [showClear]="false"
            optionValue="id"
            optionLabel="type"
            appendTo="body"
            [placeholder]="'admin.configuration.users.selectRole' | translate">
          </p-dropdown>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['license'] ? (formErrors['license'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- ACTIVE -->
      <div class="field grid">
        <label htmlFor="inputEnabled" class="col-12 mb-2 md:col-2 md:mb-0">{{ 'commons.enabled' | translate }}</label>
        <div class="col-12 md:col-10">
          <p-inputSwitch id="inputEnabled" styleClass="mb-4 mt-2" formControlName="active"></p-inputSwitch>
        </div>
      </div>

      <!-- start sub -->
      <div class="field grid" *ngIf="licenseList">
        <label htmlFor="startSubscription" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.startSub' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-calendar
            class="w-full"
            formControlName="startSubscription"
            appendTo="body"
            dateFormat="yy-mm-dd"
            [minDate]="minDate"
            [maxDate]="maxDate"
            styleClass="calendar-custom"
            (onSelect)="onCalendarPeriodConfirm()"></p-calendar>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['startSubscription'] ? (formErrors['startSubscription'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>

      <!-- end sub -->
      <div class="field grid" *ngIf="licenseList">
        <label htmlFor="endSubscription" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.endSub' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-calendar
            class="w-full"
            formControlName="endSubscription"
            appendTo="body"
            dateFormat="yy-mm-dd"
            styleClass="calendar-custom"
            placeholder="{{ 'admin.configuration.map-license.endSub' | translate }}"></p-calendar>
          <p class="text-sm text-red-500 mb-2">&nbsp;</p>
        </div>
      </div>

      <!-- number months -->
      <div class="field grid">
        <label htmlFor="numsMonths" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'admin.configuration.map-license.numsMonths' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            formControlName="numsMonths"
            id="numsMonths"
            (onInput)="changeNumsMonths()"
            [min]="errorParams.minValue"
            [max]="errorParams.maxValue">
          </p-inputNumber>
          <p class="text-sm text-red-500 mb-2">
            {{
              formErrors['changeNumsMonths()'] ? (formErrors['changeNumsMonths()'] | translate : errorParams) : '&nbsp;'
            }}
          </p>
        </div>
      </div>

      <!-- riepilogo -->
      <div class="field grid">
        <label *ngIf="confCity?.license?.priceForMonth && cityForm.controls['numsMonths'].value >= 1" class="col-12">
          {{ 'admin.configuration.map-license.cost_license' | translate }}
          {{ cityForm.controls['numsMonths'].value * confCity!.license!.priceForMonth | currency : 'EUR' }}
        </label>
      </div>
    </form>
  </div>

  <div class="col-12 flex justify-content-end">
    <p-button pRipple [outlined]="true" severity="secondary" (click)="closeModal()">
      {{ 'commons.cancel' | translate }}
    </p-button>
    <p-button
      pRipple
      [label]="'commons.confirm' | translate"
      [disabled]="cityForm && !cityForm.valid"
      class="p-button-success ml-4"
      (click)="onConfirm()"></p-button>
  </div>
</div>
