import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { UserMenuModule } from '../shared/components/user-menu/user-menu.module';
import { LanguageDropdownModule } from '../shared/modules/language-dropdown/language-dropdown.module';
import { AppFooterComponent } from './components/commons/app.footer.component';
import { AppMenuComponent } from './components/default/app.menu.component';
import { AppMenuitemComponent } from './components/default/app.menuitem.component';
import { AppSidebarComponent } from './components/default/app.sidebar.component';
import { AppTopBarComponent } from './components/commons/app.topbar.component';
import { AppLayoutComponent } from './components/default/app.layout.component';
import { AppBreadcrumbComponent } from './components/commons/app.breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CollectionLayoutComponent } from './components/collection/collection.layout.component';
import { CollectionSidebarComponent } from './components/collection/collection.sidebar.component';
import { DbTranslationModule } from '../shared/directives/db-translation/db-translation.module';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { CollectionBreadcrumbComponent } from './components/collection/collection.breadcrumb.component';
import { MapButtonsModule } from '../shared/modules/map-buttons/map-buttons.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [
    AppMenuitemComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppSidebarComponent,
    AppLayoutComponent,
    AppBreadcrumbComponent,
    CollectionLayoutComponent,
    CollectionSidebarComponent,
    CollectionBreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    TranslateModule,
    LanguageDropdownModule,
    UserMenuModule,
    BreadcrumbModule,
    ButtonModule,
    DbTranslationModule,
    AccordionModule,
    MapButtonsModule,
    OverlayPanelModule,
    AvatarModule,
    MessagesModule,
  ],
  exports: [AppLayoutComponent],
})
export class AppLayoutModule {}
