<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>

  <div class="layout-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container">
    <!--app-body-->

    <div class="w-full h-2" *ngIf="isContextExpired">
      <p-messages severity="warn">
        <ng-template pTemplate>
          <div>{{ 'commons.expired_context' | translate }}</div>
        </ng-template>
      </p-messages>
    </div>
    <app-breadcrumb></app-breadcrumb>
    <div class="layout-main mt-4">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <div class="layout-mask"></div>
</div>
