/* eslint-disable max-len */
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MediaType } from '../enums/media-type.enum';
import { City, CollectionStatus, Language } from '../interfaces/collections';
import {
  AutocompletePrediction,
  AutocompleteResponse,
  DistanceMatrixResponse,
  DistanceMatrixResponseRow,
  PlaceGeometry,
} from '../interfaces/google';
import { NominatimResult } from '../interfaces/nominatim';
import { OtpResponseV2 } from '../interfaces/otp-response-v2';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MetroMinutoService {
  // utilizzato per le modali
  public languages?: Language[];
  // utilizzato per le pagine
  public languages$ = new BehaviorSubject<Language[] | undefined>(undefined);
  public cities: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);
  public cityNames: BehaviorSubject<{ [k: string]: string }> =
    new BehaviorSubject({});

  constructor(
    public apiService: ApiService,
    public translateService: TranslateService
  ) {
    this.loadLanguagesAndCities();
  }

  /**
   * Carica Lingue e Città necessari all'app per funzionare correttamente
   */
  loadLanguagesAndCities(): void {
    const languagesFetch = this.getLanguages();
    const citiesFetch = this.getCities();

    forkJoin({
      languagesFetch,
      citiesFetch,
    }).subscribe((responses) => {
      this.languages$.next(responses.languagesFetch);
      this.languages = responses.languagesFetch;
      this.cities.next(responses.citiesFetch);
      this.cityNames.next(this.convertCitiesToCityNames(responses.citiesFetch));
    });
  }

  buildCollectionName(
    collectionName: string,
    collectionStatus?: CollectionStatus,
    versionNumber?: number
  ): Observable<string> {
    return this.translateService.get('map_editor.status'
      ).pipe(map((labelStatus) => {
        let result = collectionName;
        if (versionNumber != null) {
          result = `${collectionName} (${labelStatus.SUBVERSION} ${versionNumber})`;
        }
        if (collectionStatus === CollectionStatus.DRAFT) {
          result = `${result} [${labelStatus.DRAFT}]`;
        }
        return result;
      }));
  }

  /**
   * [NEW API] Recupera le lingue
   */
  getLanguages(): Observable<Language[]> {
    return this.apiService.get<Language[]>('languages/all').pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * [NEW API] Recupera le città
   */
  getCities(): Observable<City[]> {
    return this.apiService.get<City[]>('cities/all').pipe(
      map((data) => {
        return data;
      })
    );
  }

  // NOMINATIM
  nominatimRequest(searchText: string): Observable<NominatimResult[]> {
    const params = new HttpParams()
      .set('q', searchText)
      .set('limit', 20)
      .set('format', 'json')
      .set('addressdetails', 1);

    return this.apiService.http
      .get<NominatimResult[]>(
        `${this.apiService.confService.nominatimService}`,
        { params }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // OTP
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /* getOTP(search: string): Observable<OtpResponse> {
    // TODO GM implementare chiamata OTP
    const headers = new HttpHeaders()
    .set('Content-Type', MediaType.APPLICATION_FORM_URLENCODED);
    const options = {
      headers,
      withCredentials: true
    };

    return this.apiService.http.get<OtpResponse>(`${this.apiService.apiUrl}routing/default/${search}`, options).pipe(
      map((data) => {
        return data;
      })
    );
  }*/

  // Versione 2 di Open Trip Planner
  getOTPv2(search: string): Observable<OtpResponseV2> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      MediaType.APPLICATION_FORM_URLENCODED
    );
    const options = {
      headers,
    };

    return this.apiService.http
      .get<OtpResponseV2>(
        `${this.apiService.apiUrl}${environment.metrominuto_service}routing/${search}`,
        options
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // GOOGLE
  /**
   * Recupera il Place Google da una stringa di ricerca
   *
   * @param searchText
   * @returns
   */
  autocompleteGoogle(searchText: string): Observable<AutocompletePrediction[]> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      MediaType.APPLICATION_FORM_URLENCODED
    );

    const params = new HttpParams().set('input', searchText);

    const options = {
      headers,
      params,
    };

    return this.apiService.http
      .get<any>(
        `${this.apiService.apiUrl}${environment.metrominuto_service}place/autocomplete`,
        options
      )
      .pipe(
        map((data) => {
          const body: AutocompleteResponse = data.body;
          return body?.predictions || [];
        })
      );
  }

  getLatLonFromAutocompletePrediction(
    prediction: AutocompletePrediction
  ): Observable<PlaceGeometry | undefined> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      MediaType.APPLICATION_FORM_URLENCODED
    );

    const options = {
      headers,
    };

    const placeId = prediction.place_id;

    return this.apiService.http
      .get<any>(
        `${this.apiService.apiUrl}${environment.metrominuto_service}place/details/${placeId}`,
        options
      )
      .pipe(
        map((data) => {
          const geometry: PlaceGeometry | undefined =
            data?.body?.result?.geometry;
          return geometry;
        })
      );
  }

  getGoogleDistance(
    start: any[],
    end: any[]
  ): Observable<DistanceMatrixResponseRow[]> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      MediaType.APPLICATION_FORM_URLENCODED
    );
    const options = {
      headers,
    };

    const origins = start.toString();
    const destinations = end.toString();

    return this.apiService.http
      .get<any>(
        `${this.apiService.apiUrl}${environment.metrominuto_service}place/distancematrix?origins=${origins}&destinations=${destinations}`,
        options
      )
      .pipe(
        map((data) => {
          const body: DistanceMatrixResponse = data.body;
          return body.rows;
        })
      );
  }

  private convertCitiesToCityNames(fetchedCities: City[]): {
    [k: string]: string;
  } {
    const cities: { [k: string]: string } = {};
    if (fetchedCities && fetchedCities.length > 0) {
      fetchedCities.forEach((city) => {
        if (!!city.cityCode) {
          cities[city.cityCode] = city.name || '';
        }
      });
    }
    return cities;
  }
}
