import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CityConfiguration, CreditsConfiguration, LicenseStatus, PaginatedList } from '../interfaces/collections';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(public apiService: ApiService) {}

  /**
   * [NEW API] Aggiorna una CityConfiguration
   *
   * @param configuration
   * @param cityCode
   * @returns Observable<CityConfiguration>
   */
  updateConfiguration(
    configuration: CityConfiguration | CreditsConfiguration
  ): Observable<CityConfiguration> {
    if (configuration == null || configuration.id == null) {
      throw new Error('city code mancante');
    }

    return this.apiService
      .put<CityConfiguration>(
        `configurations/${configuration.id}`,
        configuration
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllConfiguration(page: number, size: number, order: string):  Observable<PaginatedList<CityConfiguration>> {
    return this.apiService
      .get<PaginatedList<CityConfiguration>>(
        `configurations?page=${page}&size=${size}&sort=${order}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  licenseIds(): Observable<number[]> {
    return this.apiService.get<number[]>
    ('configurations/license-ids').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUserLicensesStatus(): Observable<LicenseStatus[]> {
    return this.apiService.get<LicenseStatus[]>
    ('configurations/licenses-status').pipe(
      map((data) => {
        return data;
      })
    );
  }

}
