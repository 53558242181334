import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { MapButtonsComponent } from './map-buttons.component';

@NgModule({
  declarations: [MapButtonsComponent],
  imports: [CommonModule, FormsModule, ButtonModule, TooltipModule, OverlayPanelModule, TranslateModule],
  exports: [MapButtonsComponent],
})
export class MapButtonsModule {}
