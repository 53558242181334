/* eslint-disable id-blacklist */
import * as cytoscape from 'cytoscape';
import { License } from './admin';
import { MapStatistics } from './map-statistics';

// START - New Paginated Spring
export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface PaginatedList<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  empty: boolean;
}

export interface RequestPaginatedList {
  size?: number;
  page?: number;
  enabled?: boolean;
  sort?: {
    field: string;
    direction: 'ASC' | 'DESC';
  };
}
// END - New Paginated Spring

export interface FetchedList<T> {
  totalRows: number;
  startRow: number;
  endRow: number;
  fetchedList: T[];
}

export interface MapEditorData {
  map: MapData;
  mainMapInfo: CollectionInfo;
  versions: CollectionInfo[];
}

export interface MapData {
  collection: Collection;
  nodes: NodeData[];
  edges: EdgeData[];
  categories: Category[];
  distances: Distance[];
  languages: Language[];
  icons: Icon[];
}

export interface City {
  id?: number;
  name?: string;
  cityCode?: string;
  img?: string;
}

export interface Collection {
  id?: number;
  name: string;
  enabled: boolean;
  cityCode: string;
  speed: number;
  translations: DbTranslation[];
  icons: Icon[];
  categories?: Category[];
  distances?: Distance[];
  edges?: EdgeData[];
  nodes?: NodeData[];
  background?: string;
  icon?: string;
  header?: string;
  footer?: string;
  version?: number;
  createdAt?: string;
  mainRefId?: number;
  refId?: number;
  status?: CollectionStatus;
}

export enum CollectionStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SUBVERSION = 'SUBVERSION'
}

export interface CytoscapeNode {
  id: string;
  data: NodeData;
  position: cytoscape.Position;
}

export interface NodeData {
  id?: number;
  collectionId: number;
  categoryId?: number;
  type: string;
  image?: string;
  x: number;
  y: number;
  style?: string;
  phone?: string;
  email?: string;
  translations?: DbTranslation[];
  internalCreationId?: number; // utilizzato lato frontend per indicare la creazione di un nuovo nodo
  poi?: POI,
  // Nelle nuove versioni si trovano in POI
  // lat?: number;
  // lon?: number;
}

export interface CytoscapePosition {
  x: number;
  y: number;
}

export interface CytoscapeEdge {
  id: string;
  source: string;
  target: string;
  data: EdgeData;
}

export interface EdgeData {
  id?: number;
  collectionId?: number;
  source: number | string;
  target: number | string;
  type: string;
  meters?: number;
  minutes?: number;
  style?: string;
  translations?: DbTranslation[];
  internalCreationId?: number; // utilizzato lato frontend per indicare la creazione di un nuovo edge
}

export interface Category {
  id?: number;
  collectionId: number;
  color: string;
  priority: number;
  translations: DbTranslation[];
}

export interface Distance {
  id?: number;
  collectionId: number;
  color: string;
  min: number;
  max: number;
  translations: DbTranslation[];
}

export interface Language {
  lang: string;
  description: string;
}

export interface DbTranslation {
  id?: number;
  refId?: number;
  lang: string;
  label?: string;
  description?: string;
  url?: string;
}

export interface Statistics {
  count: number;
  sum: number;
  min: number;
  max: number;
  average: number;
}

/* export interface NodeStatistics {
  nodeId: number;
  statistics: Statistics;
}*/

export interface MapInfo {
  mapData?: MapData;
  pois?: POI[];
  statistics?: MapStatistics;
}

export interface UpdateMap {
  createNodes: NodeCrud[];
  createEdges: EdgeCrud[];

  updateEdges: EdgeCrud[];
  updateNodes: NodeCrud[];

  deleteEdges: EdgeCrud[];
  deleteNodes: NodeCrud[];
}

export interface NodeCrud {
  internalCreationId?: number; // utilizzato lato frontend per indicare la creazione di un nuovo nodo
  node: NodeData;
}

export interface EdgeCrud {
  internalCreationId?: number; // utilizzato lato frontend per indicare la creazione di un nuovo nodo
  edge: EdgeData;
  sourceInternalCreationId?: number;
  targetInternalCreationId?: number;
}

export interface Icon {
  id?: number;
  image: string;
  translations: DbTranslation[];
  collectionId?: number;
}

export interface ConfigurationTranslation {
  id?: number;
  description: string;
  lang: string;
  configuration?: number;
  credits: string;
}

export interface CityConfiguration {
  id?: number;
  refid: string;
  description: string;
  translations: ConfigurationTranslation[];
  startSubscription?: string;
  active?: boolean;
  endSubscription?: string;
  defaultHeader?: string;
  defaultBackground?: string;
  defaultFooter?: string;
  customIcon?: string;
  licenseId?: number;
  license?: License;
  city?: City;
}

export type CreditsConfiguration = Pick<CityConfiguration, 'refid' | 'description' | 'translations' | 'id'>;

export interface CollectionInfo {
  id: number;
  mainRefId: number;
  refId?: number;
	version: number;
  createdAt?: string;
  name?: string;
  status: CollectionStatus;
}

export interface POIRating {
    id: number;
    poiId: number;
    vote: number;
    voteDate: Date;
    userId?: number;
    note?: any;
}

export interface POI {
  id?: number;
  cityCode?: string;
  lat?: number;
  lon?: number;
  image?: string;
  phone?: string;
  email?: any;
  statistics?: Statistics,
  translations: DbTranslation[];
  externalRef?: string;
}

export interface LicenseStatus {
  tenantId: string;
  cityName: string;
  type: string;
  numberMaxMaps: number;
  numberMaxPoi: number;
  numberMaxVersions: number;
  currentMaps: number;
  currentPoi: number;
  startSubscription: string;
  endSubscription: string
}

export interface EdgeRoutingData {
  sourceNode?: cytoscape.NodeDataDefinition;
  targetNode?: cytoscape.NodeDataDefinition;
  collectionSpeed: number;
}