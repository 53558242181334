import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserConfigurationComponent } from './user-configuration/user-configuration.component';

const routes: Routes = [

  {
    path: '',
    component: UserConfigurationComponent,
    data: {
      title: 'admin.configuration.users.title'
    },
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserConfigurationRoutingModule { }
