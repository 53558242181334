<p-confirmDialog #cd [style]="{ width: '50vw' }">
  <ng-container *ngIf="dialog$ | async as dialog">
    <ng-template pTemplate="header">
      <h5 [innerHTML]="dialog.title"></h5>
    </ng-template>
    <ng-template pTemplate="message">
      <div [innerHTML]="dialog.bodyContent"></div>
    </ng-template>
    <ng-template pTemplate="footer">
      <ng-container *ngIf="!dialog.hideConfirmButton; else singleButton">
        <p-button pRipple [outlined]="true" severity="secondary" (click)="cd.reject()">
          {{ (dialog.cancelLabel ? dialog.cancelLabel : 'commons.no') | translate }}
        </p-button>
        <p-button
          pRipple
          [label]="(dialog.confirmLabel ? dialog.confirmLabel : 'commons.yes') | translate"
          class="p-button-success"
          (click)="cd.accept()"></p-button>
      </ng-container>
    </ng-template>
  </ng-container>
</p-confirmDialog>

<ng-template #singleButton>
  <button pButton pRipple [label]="'commons.ok' | translate" class="p-button-success" (click)="cd.reject()"></button>
</ng-template>
