import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalSelectContextComponent } from './modal-select-context.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [ModalSelectContextComponent],
  imports: [CommonModule, FormsModule, DropdownModule, TranslateModule, ButtonModule],
  exports: [ModalSelectContextComponent],
})
export class ModalSelectContextModule {}
