import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthTokenService } from '../services/auth.service';

// for Spring Boot back-end
const TOKEN_HEADER_KEY = 'Authorization';
const TENANT_HEADER_KEY = 'X-Tenant-ID';

// HttpInterceptor has intercept() method to inspect and transform HTTP requests before they are sent to server.
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthTokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.auth.getToken() != null ? `Bearer ${this.auth.getToken()}` : '';
    const tenant = this.auth.getHeaderTenantId();


    const authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, token).set(TENANT_HEADER_KEY, tenant)});
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
