import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CollectionLayoutService } from 'src/app/layout/service/collection.layout.service';

export enum MapButtonsEvents {
  DOWNLOAD_PNG = 'downloadPng',
  DOWNLOAD_PDF = 'downloadPdf',
  DOWNLOAD_SVG = 'downloadSvg',
  FULLSCREEN = 'fullscreen',
  EDIT = 'edit',
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  UNDO = 'undo',
  REDO = 'redo',
}

@Component({
  selector: 'cytoscape-map-buttons',
  templateUrl: './map-buttons.component.html',
  styleUrls: ['./map-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapButtonsComponent {
  editingMode$ = this.collectionLayoutService.editingMode$;
  undoRedoState$ = this.collectionLayoutService.undoRedoState$;

  constructor(private collectionLayoutService: CollectionLayoutService) {}

  onClickButtons(action: string): void {
    this.collectionLayoutService.mapButtonsClick.emit(action as MapButtonsEvents);
  }
}
