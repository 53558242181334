import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleEnum } from 'src/app/shared/interfaces/auth';
import { City } from 'src/app/shared/interfaces/collections';
import { ContextService } from 'src/app/shared/services/context.service';
import { homeAdminNavItems, homeMobilityNavItems } from '../../nav/home.nav';
import { LayoutService } from '../../service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent {
  model: any[];
  constructor(public layoutService: LayoutService, private contextService: ContextService) {
    if (this.contextService.getRoles().includes(RoleEnum.ROLE_ADMINISTRATOR)) {
      this.model = homeAdminNavItems;
    } else {
      this.model = homeMobilityNavItems;
    }
  }

  get currentContext(): Observable<City | null> {
    return this.contextService.currentContext.asObservable();
  }
}
