import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription, forkJoin, map, switchMap } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';
import { License } from 'src/app/shared/interfaces/admin';
import { City, CityConfiguration } from 'src/app/shared/interfaces/collections';
import { CityService } from 'src/app/shared/services/city.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { LicenseService } from 'src/app/shared/services/license.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

interface IForm {
  id: FormControl<number | undefined>;
  cityCode: FormControl<string | undefined>;
  name: FormControl<string>;
  license: FormControl<number | undefined>;
  active: FormControl<boolean>;
  startSubscription: FormControl<Date | undefined>;
  endSubscription: FormControl<Date | undefined>;
  numsMonths: FormControl<number>;
}

@Component({
  selector: 'app-add-edit-city',
  templateUrl: './add-edit-city.component.html',
  styleUrls: ['./add-edit-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditCityComponent implements OnInit, OnDestroy {
  isEditMode = false;
  confCity?: CityConfiguration;
  cityForm!: FormGroup<IForm>;
  licenseList: License[] = [];
  myLicense!: License;
  valueNumsMonths!: number;
  calendarValue?: Date[];

  minDate = moment('2020-01-01T00:00:00').startOf('day').toDate();
  maxDate = moment('2050-12-31T00:00:00').endOf('day').toDate();

  public errorParams = {
    minValue: 1,
    maxValue: 999,
  };
  public formErrors: any = {};

  private sub: Subscription = new Subscription();

  constructor(
    private cityService: CityService,
    private licenseService: LicenseService,
    private configurationService: ConfigurationService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    const cityCode: string = this.config.data?.cityCode;
    this.loadData(cityCode);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeModal(): void {
    this.ref.close();
  }

  changeNumsMonths(): void {
    if (this.cityForm.get('startSubscription')?.invalid || this.cityForm.get('numsMonths')?.invalid) {
      return;
    }
    this.valueNumsMonths = this.cityForm.get('numsMonths')?.value as number;
    this.addMonthsToEndSubscription(this.valueNumsMonths);
  }

  clearCalendar(): void {
    const clearButton: any = document.getElementsByClassName('clear-button-custom')[0];
    clearButton.click();
  }

  onCalendarPeriodConfirm(): void {
    if (this.cityForm.get('numsMonths')?.valid && this.cityForm.get('numsMonths')?.value != null) {
      this.addMonthsToEndSubscription(this.cityForm.get('numsMonths')?.value as number);
    }
  }

  onConfirm(): void {
    if (this.cityForm == null) {
      return;
    }

    this.loaderService.showLoader();

    const formValue = this.cityForm.getRawValue();

    const confData: CityConfiguration = {
      id: formValue.id,
      refid: formValue.cityCode as string,
      startSubscription: moment(formValue.startSubscription).startOf('day').format('yyyy-MM-DD HH:mm:ss'),
      endSubscription: moment(formValue.endSubscription).endOf('day').format('yyyy-MM-DD HH:mm:ss'),
      licenseId: formValue.license,
      active: formValue.active,
      description: '',
      translations: [],
    };

    if (this.confCity != null) {
      confData.description = this.confCity.description || '';
      confData.translations = this.confCity.translations || [];
    }

    let cityObs: Observable<CityConfiguration> = new Observable(obs => {
      obs.next(confData);
      obs.complete();
    });

    if (!this.isEditMode) {
      const cityData: City = {
        cityCode: this.cityForm.get('cityCode')?.value,
        name: this.cityForm.get('name')?.value,
      };
      cityObs = this.cityService.createCity(cityData).pipe(
        switchMap(city => this.cityService.getCityConfiguration(city.cityCode || '')),
        map(cityConf => {
          confData.id = cityConf.id;
          return confData;
        })
      );
      if (!this.isEditMode) {
        this.notification.info(
          this.translate.instant('admin.configuration.map-license.needToLogForUseNewCity') as string
        );
      }
    }
    this.loaderService.showLoader();

    cityObs.pipe(switchMap(cityConf => this.configurationService.updateConfiguration(cityConf))).subscribe({
      next: updateConfig => {
        this.loaderService.hideLoader();
        this.notification.success(
          this.translate.instant('admin.configuration.map-license.successCityConfiguration') as string
        );
        this.confirmSuccess(updateConfig);
      },
      error: error => {
        console.error(error);
        if (error && !!error.message) {
          this.notification.error(error.message as string);
        }
        this.loaderService.hideLoader();
      },
    });
  }

  private confirmSuccess(cityConfiguration: CityConfiguration): void {
    this.ref.close(cityConfiguration);
  }

  private loadData(cityCode?: string): void {
    const licenseList = this.licenseService.getLicense();
    let cityConfiguration: Observable<any> = new Observable(obs => {
      obs.next(null);
      obs.complete();
    });

    this.loaderService.showLoader();

    if (cityCode != null) {
      cityConfiguration = this.cityService.getCityConfiguration(cityCode);
    }

    this.licenseList = [];

    forkJoin({
      licenseList,
      cityConfiguration,
    }).subscribe({
      next: responses => {
        this.confCity = responses.cityConfiguration;
        this.setConfigInForm(this.confCity);
        this.loaderService.hideLoader();
        setTimeout(() => {
          this.licenseList = responses.licenseList;
          this.cd.detectChanges();
        }, 100);
      },
      error: error => {
        this.loaderService.hideLoader();
        console.error(error);
      },
    });
  }

  private initForm(): void {
    this.cityForm = new FormGroup<IForm>({
      id: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
        validators: [],
      }),
      cityCode: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(UtilityHelper.validatePhone)],
      }),
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      license: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      active: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      startSubscription: new FormControl<Date | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      endSubscription: new FormControl<Date | undefined>(undefined, {
        nonNullable: true,
        validators: [],
      }),
      numsMonths: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });

    this.cityForm.controls.endSubscription.disable();

    // Sottoscrivo l'evento per intercettare cambi nel form
    const formChangeSub = this.cityForm.valueChanges.subscribe((values: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(values).forEach(val => {
        this.formErrors[val] = UtilityHelper.checkError(val, this.cityForm);
      });

      if (this.formErrors.startSubscription && this.formErrors.endSubscription)
        if (this.formErrors.startSubscription) {
          this.formErrors.periodSubscription = 'admin.configuration.map-license.start_date_empty';
        } else if (this.formErrors.endSubscription) {
          this.formErrors.periodSubscription = 'admin.configuration.map-license.end_date_empty';
        }
    });

    this.sub.add(formChangeSub);
  }

  private setConfigInForm(cityConfiguration?: CityConfiguration): void {
    this.confCity = cityConfiguration;

    if (this.cityForm == null) {
      return;
    }

    this.cityForm.reset();

    // Se è una nuova città, non ho bisogno di settare i valori nel form
    if (cityConfiguration == null || cityConfiguration.city == null) {
      this.isEditMode = false;
      return;
    }

    this.isEditMode = true;

    if (cityConfiguration.id != null) {
      this.cityForm.controls.id.patchValue(cityConfiguration.id);
    }
    if (cityConfiguration.city.cityCode) {
      this.cityForm.controls.cityCode.patchValue(cityConfiguration.city.cityCode);
    }
    if (cityConfiguration.city.name) {
      this.cityForm.controls.name.patchValue(cityConfiguration.city.name);
    }
    if (cityConfiguration.licenseId != null) {
      this.cityForm.controls.license.patchValue(cityConfiguration.licenseId);
    }
    if (cityConfiguration.active) {
      this.cityForm.controls.active.patchValue(cityConfiguration.active);
    }

    const period: Date[] = [new Date(), new Date()];
    if (cityConfiguration.startSubscription) {
      period[0] = moment(cityConfiguration.startSubscription).toDate();
      this.cityForm.controls.startSubscription.patchValue(period[0]);
    }
    if (cityConfiguration.endSubscription) {
      period[1] = moment(cityConfiguration.endSubscription).toDate();
      this.cityForm.controls.endSubscription.patchValue(period[1]);
    }
    if (cityConfiguration.startSubscription && cityConfiguration.endSubscription) {
      this.cityForm.controls.numsMonths.patchValue(this.getMonthDifference(period[0], period[1]));
    }

    // Disabilito i campi non modificabili
    this.cityForm.controls.cityCode.disable();
    this.cityForm.controls.name.disable();
  }

  private getMonthDifference(startDate: Date, endDate: Date): number {
    const diffMonths =
      endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
    return diffMonths;
  }

  private addMonthsToEndSubscription(months: number): void {
    const startSub = this.cityForm.get('startSubscription')?.value;
    const dateStartSub = moment(startSub as Date);
    dateStartSub.add(months, 'months');
    this.cityForm.controls.endSubscription.patchValue(dateStartSub.startOf('day').toDate());
  }
}
