/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subscription, combineLatest, filter, map, tap } from 'rxjs';
import { ContextService } from 'src/app/shared/services/context.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService implements OnDestroy {
  breadcrumbSource$ = new BehaviorSubject<MenuItem[]>([]);

  sub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private contextService: ContextService
  ) {
    const eventsSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.createBreadcrumbs(this.route.root))
    );

    this.sub = combineLatest([
      eventsSub,
      this.contextService.currentContext.asObservable(),
      this.translate.onLangChange,
    ])
      .pipe(
        tap(([newItems, currentContext]) => {
          let nI = JSON.parse(JSON.stringify(newItems)) as MenuItem[];
          nI = nI.map(a => {
            if (a.label) {
              if (a.label === 'CONTEXT') {
                a.label = currentContext?.name || '';
              } else if (a.label === 'MAP_EDITOR') {
                a.label = a.label;
              } else {
                a.label = `${this.translate.instant(a.label)}`;
              }
            }
            return a;
          });
          this.breadcrumbSource$.next(nI);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = this.getRouteLabel(child);

      if (label) {
        const newItem: MenuItem = {
          label,
          routerLink: url,
        };

        // newItem.disabled = index === breadcrumbs.length - 1;
        // newItem.attributes = item.attributes;
        // newItem.linkProps = item.linkProps;

        breadcrumbs.push(newItem);
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private getRouteLabel(route: ActivatedRoute): string {
    const routeData = route.snapshot.data;
    return routeData['title'] || '';
  }
}
