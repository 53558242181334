<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="flex justify-content-start align-items-center">
      <div class="lg:hidden w-full mb-2">
        <language-dropdown [wFull]="true"></language-dropdown>
      </div>
    </div>
    <div class="flex justify-content-start align-items-center">
      <p-avatar icon="fas fa-user" styleClass="mr-2 border-circle" shape="circle"></p-avatar>
      <div class="ml-3">
        <span class="font-bold text-lg">{{ user?.username }}</span>
        <br />
        <span class="uppercase text-xs">{{ 'roles.' + role | translate }}</span>
      </div>
    </div>
    <hr />
    <div class="flex flex-column">
      <p-button styleClass="w-full p-2" pRipple [plain]="true" (click)="showPasswordChange.emit()" [text]="true">
        <div class="flex justify-content-start align-items-center w-full text-left">
          <i class="fas fa-unlock-alt w-2"></i>
          <span class="font-normal">{{ 'commons.changePassw' | translate }}</span>
        </div>
      </p-button>

      <p-button
        class="mt-2"
        *ngIf="role === 'ROLE_MOBILITY_MANAGER'"
        styleClass="w-full p-2"
        pRipple
        [plain]="true"
        (click)="showLicenseStatus.emit()"
        [text]="true">
        <div class="flex justify-content-start align-items-center w-full text-left">
          <i class="fas fa-certificate w-2"></i>
          <span class="font-normal">{{ 'license_status.title' | translate }}</span>
        </div>
      </p-button>
    </div>
    <hr />
    <div class="flex flex-column">
      <p-button
        class="mb-2"
        *ngIf="hasMultipleTenants"
        styleClass="w-full p-2"
        pRipple
        [plain]="true"
        (click)="showSelectContext.emit()"
        [text]="true">
        <div class="flex justify-content-start align-items-center w-full text-left">
          <i class="fas fa-globe w-2"></i>
          <span class="font-normal">{{ 'commons.changeContext' | translate }}</span>
        </div>
      </p-button>

      <p-button styleClass="w-full p-2" pRipple [plain]="true" (click)="exportUserData()" [text]="true">
        <div class="flex justify-content-start align-items-center w-full text-left">
          <i class="fas fa-cloud-download-alt w-2"></i>
          <span class="font-normal">{{ 'commons.export_context' | translate }}</span>
        </div>
      </p-button>
    </div>
    <hr />
    <div class="flex flex-column">
      <p-button styleClass="w-full p-2" pRipple severity="danger" (click)="logout()" [text]="true">
        <div class="flex justify-content-start align-items-center w-full text-left">
          <i class="fas fa-sign-out-alt w-2"></i>
          <span class="font-normal">{{ 'commons.logout' | translate }}</span>
        </div>
      </p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<!--<ng-container *ngIf="itemsMenu$ | async as itemsMenu">
  <p-menu #menu [popup]="true" [model]="itemsMenu" appendTo="body" [baseZIndex]="2000">
    <ng-template pTemplate="item" let-item>
      <a class="p-menuitem-link flex justify-content-between align-items-center p-3">
        <div class="flex">
          <div class="w-2rem">
            <i [class]="item.icon"></i>
          </div>
          <div>{{ item.label | translate }}</div>
        </div>
        <div>
          <span *ngIf="item.shortcut" [class]="item.shortcutClass">{{ item.shortcut }}</span>
          <p-badge *ngIf="item.badge" [value]="item.badge" [severity]="item.badgeSeverity"></p-badge>
        </div>
      </a>
    </ng-template>
  </p-menu>
  <button *ngIf="itemsMenu && itemsMenu.length > 0" class="p-link layout-topbar-button" (click)="menu.toggle($event)">
    <i class="pi pi pi-cog"></i>
    <span>{{ 'commons.settings' | translate }}</span>
  </button>
</ng-container>-->
