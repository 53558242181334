import {ChangeDetectorRef, Injectable, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import { LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DbTranslationHelper } from '../../helpers/db-translation.helper';
import { DbTranslation } from '../../interfaces/collections';

@Injectable()
@Pipe({
  name: 'dbTranslation',
  pure: false // required to update the value when the promise is resolved
})
export class DbTranslationPipe implements PipeTransform, OnDestroy {
  value = '';
  lastTranslations: DbTranslation[] | null = null;
  lastField: 'label' | 'description' | 'url' | null = null;
  onTranslationChange: Subscription | undefined;
  onLangChange: Subscription | undefined;
  onDefaultLangChange: Subscription | undefined;

  constructor(private translate: TranslateService, private _ref: ChangeDetectorRef) {
  }

  updateValue(translations: DbTranslation[], field: 'label' | 'description' | 'url', lang: string, replaceNewLine = false): void {
    if (!!field && !!translations && translations.length > 0) {
        this.lastTranslations = translations;
        let value = DbTranslationHelper.translation(translations, field, lang);
        if (replaceNewLine) {
          value = value.replace(/\\n/g, '<br/>');
        } else {
          value = value.replace(/\\n/g, ' ');
        }
        this.value = value;
        this._ref.markForCheck();
    }
  }

  transform(translations: DbTranslation[], field: 'label' | 'description' | 'url', replaceNewLine = false): any {
    if (!translations || translations.length <= 0 || !field) {
      return '';
    }

    // store the query, in case it changes
    this.lastTranslations = translations;

    // store the params, in case they change
    this.lastField = field;

    // set the value
    this.updateValue(translations, field, this.translate.currentLang, replaceNewLine);

    // if there is a subscription to onLangChange, clean it
    this._dispose();

    // subscribe to onTranslationChange event, in case the translations change
    if (!this.onTranslationChange) {
      this.onTranslationChange = this.translate.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
        if (this.lastTranslations && event.lang === this.translate.currentLang) {
          this.lastTranslations = null;
          this.updateValue(translations, field, event.lang, replaceNewLine);
        }
      });
    }

    // subscribe to onLangChange event, in case the language changes
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.lastTranslations && this.lastTranslations.length > 0) {
          this.lastTranslations = null; // we want to make sure it doesn't return the same value until it's been updated
          this.updateValue(translations, field, event.lang, replaceNewLine);
        }
      });
    }

    // subscribe to onDefaultLangChange event, in case the default language changes
    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe((event) => {
        if (this.lastTranslations && this.lastTranslations.length > 0) {
          this.lastTranslations = null; // we want to make sure it doesn't return the same value until it's been updated
          this.updateValue(translations, field, event.lang, replaceNewLine);
        }
      });
    }

    return this.value;
  }

  ngOnDestroy(): void {
    this._dispose();
  }

  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this.onTranslationChange !== 'undefined') {
      this.onTranslationChange.unsubscribe();
      this.onTranslationChange = undefined;
    }
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
    if (typeof this.onDefaultLangChange !== 'undefined') {
      this.onDefaultLangChange.unsubscribe();
      this.onDefaultLangChange = undefined;
    }
  }

}