<div class="grid grid-nogutter mt-2">
  <div class="col-12">
    <form [formGroup]="userDettForm">
      <!-- USERNAME -->
      <div class="field grid">
        <label htmlFor="username" class="col-12 mb-2 md:col-2 md:mb-0">Username</label>
        <div class="col-12 md:col-10">
          <input
            pInputText
            class="w-full"
            id="username"
            placeholder="Username"
            formControlName="username"
            type="text" />
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['username'] ? (formErrors['username'] | translate : usernameParams) : '&nbsp;' }}
          </p>
        </div>
      </div>
      <!-- EMAIL -->
      <div class="field grid">
        <label htmlFor="email" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
        <div class="col-12 md:col-10">
          <input pInputText class="w-full" id="email" placeholder="Email" formControlName="email" type="text" />
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['email'] ? (formErrors['email'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>
      <!--  DATA ORA ULTIMO ACCESSO -->
      <div class="field grid" *ngIf="isEditMode">
        <label htmlFor="lastAccessTime" class="col-12 mb-2 md:col-2 md:mb-0">{{
          'admin.configuration.users.lastAccessTime' | translate
        }}</label>
        <div class="col-12 md:col-10">
          <input
            pInputText
            class="w-full"
            id="lastAccessTime"
            placeholder="{{ 'admin.configuration.users.lastAccessTime' | translate }}"
            formControlName="lastAccessTime"
            type="text" />
          <p class="text-sm text-red-500 mb-2">&nbsp;</p>
        </div>
      </div>
      <!-- ACTIVE -->
      <div class="field grid">
        <label htmlFor="inputEnabled" class="col-12 mb-2 md:col-2 md:mb-0">{{ 'commons.enabled' | translate }}</label>
        <div class="col-12 md:col-10">
          <p-inputSwitch id="inputEnabled" styleClass="mb-4 mt-2" formControlName="active"></p-inputSwitch>
        </div>
      </div>
      <!-- ROLE -->
      <div class="field grid">
        <label htmlFor="role" class="col-12 mb-2 md:col-2 md:mb-0">{{
          'admin.configuration.users.role' | translate
        }}</label>
        <div class="col-12 md:col-10">
          <p-dropdown
            class="w-full"
            styleClass="w-full"
            [options]="listRoles"
            formControlName="role"
            [showClear]="false"
            optionValue="role"
            appendTo="body"
            (onChange)="onChangeRole($event)"
            [placeholder]="'admin.configuration.users.selectRole' | translate">
            <ng-template let-option pTemplate="selectedItem">
              <span *ngIf="option">{{ 'roles.' + option.role | translate }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ 'roles.' + option.role | translate }}</span>
            </ng-template>
          </p-dropdown>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['role'] ? (formErrors['role'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>
      <!-- CITY -->
      <div class="field grid" *ngIf="isMobilityManager$ | async">
        <label htmlFor="city" class="col-12 mb-2 md:col-2 md:mb-0">{{ 'admin.city' | translate }}</label>
        <div class="col-12 md:col-10" *ngIf="cities$ | async as cities">
          <p-dropdown
            class="w-full"
            styleClass="w-full"
            [options]="cities"
            formControlName="city"
            [showClear]="false"
            optionValue="cityCode"
            optionLabel="name"
            appendTo="body"
            [placeholder]="'admin.configuration.users.selectContext' | translate">
          </p-dropdown>
          <p class="text-sm text-red-500 mb-2">
            {{ formErrors['city'] ? (formErrors['city'] | translate) : '&nbsp;' }}
          </p>
        </div>
      </div>
    </form>
  </div>

  <div class="col-12 flex justify-content-end">
    <p-button pRipple [outlined]="true" severity="secondary" (click)="closeModal()">
      {{ 'commons.cancel' | translate }}
    </p-button>
    <p-button
      pRipple
      [label]="'commons.confirm' | translate"
      [disabled]="userDettForm && !userDettForm.valid"
      class="p-button-success ml-4"
      (click)="onConfirm()"></p-button>
  </div>
</div>
