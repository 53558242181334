import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';
import { ResponseAuth, Role, RoleEnum, User, UserCityRoles } from 'src/app/shared/interfaces/auth';
import { City } from 'src/app/shared/interfaces/collections';
import { ContextService } from 'src/app/shared/services/context.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { MetroMinutoService } from 'src/app/shared/services/metrominuto.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { UserService } from 'src/app/shared/services/user.service';

interface IForm {
  id: FormControl<number | undefined>;
  email: FormControl<string>;
  username: FormControl<string>;
  password: FormControl<string | undefined>;
  lastAccessTime: FormControl<string>;
  active: FormControl<boolean>;
  role: FormControl<RoleEnum | undefined>;
  city: FormControl<string | undefined>;
}

@Component({
  selector: 'app-modal-add-edit-user',
  templateUrl: './modal-add-edit-user.component.html',
  styleUrls: ['./modal-add-edit-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddEditUserComponent implements OnDestroy, OnInit {
  user?: User;
  userDettForm!: FormGroup<IForm>;
  usernameParams = UtilityHelper.usernameLengths;
  isEditMode = false;
  savedActiveBeforeChanges!: boolean;

  listRoles!: Role[];
  cities!: City[];
  isMobilityManager$ = new BehaviorSubject<boolean>(true);

  public formErrors: any = {};

  private sub: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private metroMinutoService: MetroMinutoService,
    private translateService: TranslateService,
    private roleService: RoleService,
    private loaderService: LoaderService,
    private notification: NotificationService,
    private context: ContextService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private cd: ChangeDetectorRef
  ) {
    const cs = this.cities$.subscribe(data => {
      this.cities = data;
    });
    this.sub.add(cs);
    this.initForm();
  }

  get currentUser(): ResponseAuth | null {
    return this.context.getUser();
  }

  get cities$(): Observable<City[]> {
    return this.metroMinutoService.cities.asObservable();
  }

  ngOnInit(): void {
    const userId: number = this.config.data?.userId;
    this.loadData(userId);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeModal(): void {
    this.ref.close();
  }

  onChangeRole(evt: any): void {
    this.isMobilityManager$.next(evt.value === RoleEnum.ROLE_MOBILITY_MANAGER);
  }

  onConfirm(): void {
    if (this.userDettForm == null) {
      return;
    }

    this.loaderService.showLoader();

    const formValue = this.userDettForm.value;
    const userCitiesRolesList: UserCityRoles[] = this.makeUserCitiesRoles(formValue);

    const user: Partial<User> = {
      username: formValue.username,
      active: formValue.active || false,
      lastAccessTime: formValue.lastAccessTime,
      email: formValue.email,
      // password: this.user?.password ? this.user.password : '', // this.userDettForm.get('password')?.value,
      userCityRoles: userCitiesRolesList,
    };

    if (this.isEditMode) {
      user.id = this.user?.id;
      this.userService.updateUser(user as User).subscribe({
        next: response => {
          this.loaderService.hideLoader();
          this.notification.success(this.translateService.instant('admin.configuration.users.user_edited') as string);
          if (this.savedActiveBeforeChanges !== user.active) {
            this.notification.success(
              this.translateService.instant('commons.successSendEmailTo', {
                to: user.email,
              }) as string
            );
          }
          this.confirmSuccess(response);
        },
        error: error => {
          console.error(error);
          if (error && !!error.message) {
            this.notification.error(error.message as string);
          }
          this.loaderService.hideLoader();
        },
      });
    } else {
      this.userService.saveUser(user as User).subscribe({
        next: response => {
          this.loaderService.hideLoader();
          this.notification.success(this.translateService.instant('admin.configuration.users.user_created') as string);
          this.notification.success(
            this.translateService.instant('commons.successSendEmailTo', {
              to: user.email,
            }) as string
          );
          if (!user.active) {
            this.notification.warning(
              this.translateService.instant('admin.configuration.users.userIsNotActive') as string
            );
          }
          this.confirmSuccess(response);
        },
        error: error => {
          console.error(error);
          if (error && !!error.message) {
            this.notification.error(error.message as string);
          }
          this.loaderService.hideLoader();
        },
      });
    }
  }

  private confirmSuccess(user: User): void {
    this.ref.close(user);
  }

  private loadData(idUser?: number): void {
    const roles = this.roleService.getAllRoles();
    let user: Observable<any> = new Observable(obs => {
      obs.next(null);
      obs.complete();
    });

    this.loaderService.showLoader();

    if (idUser != null) {
      user = this.userService.getByUserById(idUser);
    }

    forkJoin({
      roles,
      user,
    }).subscribe({
      next: responses => {
        this.listRoles = responses.roles;
        this.user = responses.user;
        this.setUserInForm(this.user);
        this.loaderService.hideLoader();
        this.cd.detectChanges();
      },
      error: error => {
        this.loaderService.hideLoader();
        console.error(error);
      },
    });
  }

  private initForm(): void {
    this.userDettForm = new FormGroup<IForm>({
      id: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(UtilityHelper.validateEmail)],
      }),
      username: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(this.usernameParams.minLength),
          Validators.maxLength(this.usernameParams.maxLength),
        ],
      }),
      password: new FormControl<string | undefined>(undefined, {
        nonNullable: true,
      }),
      lastAccessTime: new FormControl<string>('', {
        nonNullable: true,
      }),
      active: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
      role: new FormControl<any | undefined>(RoleEnum.ROLE_MOBILITY_MANAGER, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      city: new FormControl<any | undefined>(undefined, {
        nonNullable: true,
      }),
    });

    // Sottoscrivo l'evento per intercettare cambi nel form
    const formChangeSub = this.userDettForm.valueChanges.subscribe((values: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(values).forEach(val => {
        this.formErrors[val] = UtilityHelper.checkError(val, this.userDettForm);
      });

      this.formErrors.city = '';

      if (values.role === RoleEnum.ROLE_MOBILITY_MANAGER && !values.city) {
        this.userDettForm?.setErrors({
          city: true,
        });
        this.formErrors.city = 'validators.required';
      } else {
        this.formErrors.city = '';
      }
    });

    this.sub.add(formChangeSub);
  }

  private setUserInForm(user?: User): void {
    this.user = user;

    if (this.userDettForm == null) {
      return;
    }

    this.userDettForm.reset();

    // Se è un nuovo user, non ho bisogno di settare i valori nel form
    if (user == null) {
      this.isEditMode = false;
      return;
    }

    this.isEditMode = true;
    this.savedActiveBeforeChanges = user.active;

    if (user.id != null) {
      this.userDettForm.controls.id.patchValue(user.id);
    }
    if (user.username) {
      this.userDettForm.controls.username.patchValue(user.username);
      this.userDettForm.controls.username.disable();
    }
    if (user.email) {
      this.userDettForm.controls.email.patchValue(user.email);
    }
    if (user.lastAccessTime) {
      this.userDettForm.controls.lastAccessTime.patchValue(user.lastAccessTime);
      this.userDettForm.controls.lastAccessTime.disable();
    }
    if (user.active) {
      this.userDettForm.controls.active.patchValue(user.active);
    }
    if (user.userCityRoles != null) {
      if (user.userCityRoles.length === 1) {
        // il mobility manager avrà solo un ruolo
        this.userDettForm.controls.role.patchValue(user.userCityRoles[0].role?.role);
        if (user?.userCityRoles[0]?.role?.role === RoleEnum.ROLE_MOBILITY_MANAGER) {
          this.userDettForm.controls.city.patchValue(user?.userCityRoles[0].city?.cityCode);
          this.isMobilityManager$.next(true);
        }
      } else {
        // se è un amministratore
        this.userDettForm.controls.role.patchValue(RoleEnum.ROLE_ADMINISTRATOR);
        this.isMobilityManager$.next(false);
      }
    }

    if (this.currentUser && user && this.currentUser.userId === user.id) {
      this.userDettForm.controls.role.disable();
    }
  }

  private makeUserCitiesRoles(formValue: any): UserCityRoles[] {
    const userCitiesRolesList: UserCityRoles[] = [];
    const roleDao = this.listRoles.find(elem => elem.role === formValue.role); // get Role entity with id and name
    if (roleDao != null) {
      if (roleDao.role === RoleEnum.ROLE_ADMINISTRATOR) {
        this.cities.forEach(item => {
          userCitiesRolesList.push({
            cityId: item.cityCode as string,
            roleId: roleDao.id,
            userId: this.user?.id as number,
          });
        });
      } else {
        userCitiesRolesList.push({
          cityId: formValue.city as string,
          roleId: roleDao.id,
          userId: this.user?.id as number,
        });
      }
    }
    return userCitiesRolesList;
  }
}
