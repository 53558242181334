import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { City } from 'src/app/shared/interfaces/collections';

@Component({
  selector: 'app-modal-select-context',
  templateUrl: './modal-select-context.component.html',
  styleUrls: ['./modal-select-context.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSelectContextComponent implements OnInit {
  cities: City[] = [];
  selectedCityCode?: string;
  hideCancelButton = false;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.hideCancelButton = this.config.data?.hideCancelButton;
    this.cities = this.config.data?.cities || [];
    this.selectedCityCode = this.config.data?.selectedCityCode;
  }

  closeModal(): void {
    this.ref.close();
  }

  onConfirm(): void {
    const city = this.cities.find(val => val.cityCode === this.selectedCityCode);
    if (city != null && city.cityCode) {
      this.ref.close(city);
    } else {
      this.ref.close();
    }
  }
}
