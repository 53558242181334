import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DialogService } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { Columns } from 'src/app/shared/interfaces/admin';
import { ResponseAuth, RoleEnum, User, UserConfig } from 'src/app/shared/interfaces/auth';
import { PaginatedList } from 'src/app/shared/interfaces/collections';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import * as XLSX from 'xlsx';
import { ModalAddEditUserComponent } from '../modal-add-edit-user/modal-add-edit-user.component';

@Component({
  selector: 'app-user-configuration',
  templateUrl: './user-configuration.component.html',
  styleUrls: ['./user-configuration.component.scss'],
})
export class UserConfigurationComponent implements OnInit {
  userData: UserConfig[] = [];
  // table handle
  columns: Columns[] = [];
  first = 0;
  rows = 20;
  page = 0; // starts from page index 0
  loading = false; // spinner used for loading data of table
  totalRecords!: number; // total rows
  orderUserData!: string;

  currentUser: ResponseAuth | null = null;


  constructor(
    private translateService: TranslateService,
    private contextService: ContextService,
    private notification: NotificationService,
    private userService: UserService,
    private confirmService: ConfirmService,
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) {
    this.columns = [
      {
        field: 'username',
        header: 'Username',
      },
      {
        field: 'email',
        header: 'Email',
      },
      {
        field: 'active',
        header: 'admin.configuration.users.active',
      },
      {
        field: 'role',
        header: 'admin.configuration.users.role',
      },
      {
        field: 'city',
        header: 'commons.city',
      },
      {
        field: 'lastAccessTime',
        header: 'admin.configuration.users.lastAccessTime',
      },
    ];
  }

  ngOnInit(): void {
    this.currentUser = this.contextService.getUser();
    this.getUserData();
  }

  // event lazy load
  lazyLoad(event: TableLazyLoadEvent): void {
    if (event.first != null && event.rows != null) {
      this.page = event.first / event.rows; // calculate rows
    }
    if (event.sortField != null && event.sortOrder != null) {
      this.orderUserData = event.sortOrder === 1 ? `${event.sortField},ASC` : `${event.sortField},DESC`;
    }

    this.getUserData(this.page, this.rows, this.orderUserData);
  }

  getUserData(page: number = 0, rows: number = 20, order: string = 'id,DESC'): void {
    this.loaderService.showLoader();
    this.userService.getAllUsers(page, rows, order).subscribe({
      next: (response: PaginatedList<User>) => {
        this.totalRecords = response.totalElements;
        this.rows = response.size;
        this.userData = response.content.map(u => {
          const userCityRoles = u.userCityRoles || [];

          const role = userCityRoles.length === 1 ? RoleEnum.ROLE_MOBILITY_MANAGER : RoleEnum.ROLE_ADMINISTRATOR;
          const city = role === RoleEnum.ROLE_MOBILITY_MANAGER ? userCityRoles[0].city?.name || '' : '-';

          const userConfig: UserConfig = { ...u, role, city };
          return userConfig;
        });
        this.loaderService.hideLoader();
      },
      error: err => {
        console.error(err);
        this.loaderService.hideLoader();
      },
    });
  }

  editUser(row: User): void {
    this.openModalUser(row.id);
  }

  addUser(): void {
    this.openModalUser();
  }

  exportExcel(): void {
    this.loaderService.showLoader();
    try {
      if (!this.userData || this.userData.length === 0) {
        console.warn('Nessun dato disponibile');
        return;
      }
      const headers = [
        this.translateService.instant('commons.username'),
        this.translateService.instant('node.email'),
        this.translateService.instant('admin.configuration.users.active'),
        this.translateService.instant('admin.configuration.users.role'),
        this.translateService.instant('commons.city'),
        this.translateService.instant('admin.configuration.users.lastAccessTime'),
      ];
      const data: any[] = this.userData.map(item => ({
        [headers[0]]: item.username,
        [headers[1]]: item.email,
        [headers[2]]: item.active ? this.translateService.instant('commons.yes') : this.translateService.instant('commons.no'),
        [headers[3]]: this.translateService.instant(`roles.${item.role}`),
        [headers[4]]: item.city,
        [headers[5]]: item.lastAccessTime,
      }));

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data,);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'ConfigUtenti');
      XLSX.writeFile(workbook, `${this.translateService.instant('commons.userConfigFileName')}.xlsx`);
    } catch (error) {
      console.error('errore durante esportazione', error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  exportPdf(): void {
    this.loaderService.showLoader();

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
    });

    doc.setFontSize(18);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    doc.text(this.translateService.instant('commons.userConfigFileName'), 30, 35);

    const headers = [[
      '#',
      this.translateService.instant('commons.username'),
      this.translateService.instant('node.email'),
      this.translateService.instant('admin.configuration.users.active'),
      this.translateService.instant('admin.configuration.users.role'),
      this.translateService.instant('commons.city'),
      this.translateService.instant('admin.configuration.users.lastAccessTime'),
    ]];

    const body = this.userData.map((row, index) => [
        index + 1,
        row.username,
        row.email,
        row.active ? this.translateService.instant('commons.yes') :  this.translateService.instant('commons.no'),
        this.translateService.instant(`roles.${row.role}`),
        row.city,
        row.lastAccessTime ? row.lastAccessTime : this.translateService.instant('admin.configuration.users.neverLogin'),
    ]);

    autoTable(doc, { startY: 65, head:headers, body });

    doc.save(`${this.translateService.instant('commons.userConfigFileName')}.pdf`);

    this.loaderService.hideLoader();
  }


  async removeUser(row: User): Promise<void> {
    const title: string = this.translateService.instant('admin.configuration.users.removeUser');
    const message: string = this.translateService.instant('admin.configuration.users.removeUserQuestion', {
      name: row.username,
    });
    const action = await this.confirmService.showDialog(title, message);

    if (action !== 'confirm') {
      return;
    }

    this.userService.delete(row.id as number).subscribe({
      next: (response: boolean) => {
        if (response) {
          this.notification.success(
            this.translateService.instant('admin.configuration.users.succDeleteUser') as string
          );
        } else {
          this.notification.error(this.translateService.instant('commons.error') as string);
        }
      },
      error: () => console.error('error on delete user'),
      complete: () => this.getUserData(),
    });
  }

  private onConfirmSuccess(): void {
    this.getUserData();
  }

  private openModalUser(userId?: number): void {
    const header = userId != null ? 'admin.configuration.users.editUser' : 'admin.configuration.users.addUser';
    const dialogRef = this.dialogService.open(ModalAddEditUserComponent, {
      header: this.translateService.instant(header),
      closable: true,
      contentStyle: { overflow: 'auto' },
      styleClass: 'responsive-dialog',
      baseZIndex: 100,
      data: {
        userId,
      },
    });

    const subDialog = dialogRef.onClose.subscribe((user: User) => {
      if (user) {
        this.onConfirmSuccess();
      }
      subDialog.unsubscribe();
    });
  }
}
