import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { LoaderService } from './shared/services/loader.service';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CookieService } from './shared/services/cookie.service';
import { AppConfigService } from './shared/services/app-config.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = '';

  private sub?: Subscription;

  constructor(
    private titleService: Title,

    private cookieService: CookieService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private config: PrimeNGConfig,
    private appConfig: AppConfigService
  ) {
    // iconSet singleton
    console.log(`App Version ${environment.version.version}: ${environment.version.commitHash}`);
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.appConfig.name);
    this.initLang();
    this.cookieService.initService();
  }

  ngAfterViewInit(): void {
    const loadingIndicator = document.getElementById('fullpage-loader');
    if (loadingIndicator) {
      this.loaderService.setSpinnerContainer(loadingIndicator);
      this.loaderService.hideLoader();
    }
    // Forzo il reload per evitare di passare su pagine sporche
    // this.router.navigate([''], { replaceUrl: true });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.cookieService.destroyService();
  }

  private initLang(): void {
    this.translate.addLangs(environment.supportedLanguages);
    this.translate.setDefaultLang(environment.defaultLanguage);

    const browserLang = this.translate.getBrowserLang();
    const isBrowserLangSupported = environment.supportedLanguages.findIndex(val => val === browserLang) >= 0;
    const language = isBrowserLangSupported ? browserLang : environment.defaultLanguage;
    this.translate.use(language || 'en');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const primengLang = this.translate.instant('primeng');
    if (primengLang != null) {
      this.config.setTranslation(primengLang as Translation);
    }
    this.sub = this.translate.onLangChange.subscribe(() => {
      const primengNewLang = this.translate.instant('primeng');
      this.config.setTranslation(primengNewLang as Translation);
    });
  }
}
