import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';
import { License } from 'src/app/shared/interfaces/admin';
import { LicenseService } from 'src/app/shared/services/license.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

interface IForm {
  id: FormControl<number | undefined>;
  type: FormControl<string>;
  numberMaxMaps: FormControl<number>;
  numberMaxPoi: FormControl<number>;
  numberMaxVersions: FormControl<number>;
  priceForMonth: FormControl<number>;
}

@Component({
  selector: 'app-add-edit-license',
  templateUrl: './add-edit-license.component.html',
  styleUrls: ['./add-edit-license.component.scss'],
})
export class AddEditLicenseComponent implements OnDestroy, OnInit {
  isEditMode = false;
  licenseForm!: FormGroup<IForm>;

  public formErrors: any = {};
  public paramsError = {
    minValue: 1,
    maxValue: 999,
  };
  private license?: License;

  private sub: Subscription = new Subscription();

  constructor(
    private licenseService: LicenseService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private notification: NotificationService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    const licenseId: number = this.config.data?.licenseId;
    this.loadData(licenseId);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeModal(): void {
    this.ref.close();
  }

  onConfirm(): void {
    if (this.licenseForm == null) {
      return;
    }

    const license: License = {
      id: this.licenseForm.controls.id.value as number,
      numberMaxMaps: this.licenseForm.controls.numberMaxMaps.value,
      numberMaxPoi: this.licenseForm.controls.numberMaxPoi.value,
      numberMaxVersions: this.licenseForm.controls.numberMaxVersions.value,
      type: this.licenseForm.controls.type.value,
      priceForMonth: this.licenseForm.controls.priceForMonth.value,
    };

    this.loaderService.showLoader();

    if (this.isEditMode) {
      this.licenseService.updateLicense(license, license.id).subscribe({
        next: response => {
          this.loaderService.hideLoader();
          this.notification.success(
            this.translate.instant('admin.configuration.map-license.successEditLicense') as string
          );
          this.confirmSuccess(response);
        },
        error: error => {
          console.error(error);
          if (error && !!error.message) {
            this.notification.error(error.message as string);
          }
          this.loaderService.hideLoader();
        },
      });
    } else {
      this.licenseService.saveLicense(license).subscribe({
        next: response => {
          this.loaderService.hideLoader();
          this.notification.success(
            this.translate.instant('admin.configuration.map-license.successCreatedLicense') as string
          );
          this.confirmSuccess(response);
        },
        error: error => {
          console.error(error);
          if (error && !!error.message) {
            this.notification.error(error.message as string);
          }
          this.loaderService.hideLoader();
        },
      });
    }
  }

  private confirmSuccess(license: License): void {
    this.ref.close(license);
  }

  private loadData(licenseId?: number): void {
    if (licenseId == null) {
      this.setLicenseInForm();
      return;
    }

    this.loaderService.showLoader();

    this.licenseService.getLicenseById(licenseId).subscribe({
      next: (response: License) => {
        this.license = response;
        this.setLicenseInForm(this.license);
        this.loaderService.hideLoader();
      },
      error: error => {
        this.loaderService.hideLoader();
        console.error(error);
      },
    });
  }

  private initForm(): void {
    this.licenseForm = new FormGroup<IForm>({
      id: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
      }),
      type: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      numberMaxMaps: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      numberMaxPoi: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      numberMaxVersions: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      priceForMonth: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });

    // Sottoscrivo l'evento per intercettare cambi nel form
    const formChangeSub = this.licenseForm.valueChanges.subscribe((values: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(values).forEach(val => {
        this.formErrors[val] = UtilityHelper.checkError(val, this.licenseForm);
      });
    });

    this.sub.add(formChangeSub);
  }

  private setLicenseInForm(license?: License): void {
    this.license = license;

    if (this.licenseForm == null) {
      return;
    }

    this.licenseForm.reset();

    // Se è una nuova licenza, non ho bisogno di settare i valori nel form
    if (license == null) {
      this.isEditMode = false;
      return;
    }

    this.isEditMode = true;

    if (license.id != null) {
      this.licenseForm.controls.id.patchValue(license.id);
    }
    if (license.numberMaxMaps != null) {
      this.licenseForm.controls.numberMaxMaps.patchValue(license.numberMaxMaps);
    }
    if (license.numberMaxPoi != null) {
      this.licenseForm.controls.numberMaxPoi.patchValue(license.numberMaxPoi);
    }
    if (license.numberMaxVersions != null) {
      this.licenseForm.controls.numberMaxVersions.patchValue(license.numberMaxVersions);
    }
    if (license.type) {
      this.licenseForm.controls.type.patchValue(license.type);
    }
    if (license.priceForMonth != null) {
      this.licenseForm.controls.priceForMonth.patchValue(license.priceForMonth);
    }
  }
}
