/* eslint-disable max-len */
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category, City, CollectionStatus, Distance, MapInfo } from 'src/app/shared/interfaces/collections';
import { ContextService } from 'src/app/shared/services/context.service';
import { CollectionLayoutService } from '../../service/collection.layout.service';

export interface INavData {
  name?: string;
  url?: string | any[];
  href?: string;
  icon?: string;
  class?: string;
  type?: 'categories' | 'distances' | 'icons' | 'command';
  disabled?: boolean;
  expandable?: boolean;
  command?: () => void;
}

@Component({
  selector: 'collection-sidebar',
  templateUrl: './collection.sidebar.component.html',
  styleUrls: ['./collection.sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionSidebarComponent {
  selectedCategory$ = new BehaviorSubject<Category | undefined>(undefined);
  selectedDistance$ = new BehaviorSubject<Distance | undefined>(undefined);

  editingMode$ = this.collectionLayoutService.editingMode$;
  mapInfo$ = this.collectionLayoutService.mapInfo$;
  isMapEditor$ = this.collectionLayoutService.isMapEditor$;
  sidebarEvent = this.collectionLayoutService.sidebarEvent;

  constructor(
    public el: ElementRef,
    public translate: TranslateService,
    private contextService: ContextService,
    private collectionLayoutService: CollectionLayoutService,
    private location: Location
  ) {}

  get currentContext(): Observable<City | null> {
    return this.contextService.currentContext.asObservable();
  }

  get mapInfo(): MapInfo | undefined {
    return this.mapInfo$.getValue() || undefined;
  }

  public isCollectionDraft(): boolean {
    return this.mapInfo?.mapData?.collection?.status === CollectionStatus.DRAFT;
  }

  public hasIcons(): boolean {
    return (this.mapInfo?.mapData?.icons?.length || 0) > 0;
  }

  public hasCategories(): boolean {
    return (this.mapInfo?.mapData?.categories?.length || 0) > 0;
  }

  public hasDistances(): boolean {
    return (this.mapInfo?.mapData?.distances?.length || 0) > 0;
  }

  public showStatistics(): any {
    const statistics = this.mapInfo?.statistics;
    const hasCountPois = (statistics?.countPois || 0) > 0;
    const hasPoisForCategory = (statistics?.countPoisForCategory?.length || 0) > 0;
    const hasCountLinks = (statistics?.countLinks || 0) > 0;
    const hasTotalDistance = (statistics?.totalDistance || 0) > 0;
    const hasTotalTime = (statistics?.totalTime || 0) > 0;
    const showStatistics = hasCountPois || hasPoisForCategory || hasCountLinks || hasTotalDistance || hasTotalTime;
    return {
      hasCountPois,
      hasPoisForCategory,
      hasCountLinks,
      hasTotalDistance,
      hasTotalTime,
      showStatistics,
    };
  }

  public onSelectCategory(item: Category): void {
    const selectedCategory = this.selectedCategory$.getValue();
    if (selectedCategory?.id === item.id) {
      this.selectedCategory$.next(undefined);
    } else {
      this.selectedCategory$.next(item);
    }
    this.collectionLayoutService.itemClick.emit({ type: 'category', item });
  }

  public onSelectDistance(item: Distance): void {
    const selectedDistance = this.selectedDistance$.getValue();
    if (selectedDistance?.id === item.id) {
      this.selectedDistance$.next(undefined);
    } else {
      this.selectedDistance$.next(item);
    }
    this.collectionLayoutService.itemClick.emit({ type: 'distance', item });
  }

  public goBack(): void {
    this.location.back();
  }
}
