<div class="grid grid-nogutter mt-2">
  <div class="col-12 my-3 flex">
    <p-dropdown
      class="w-full"
      styleClass="w-full"
      [options]="cities"
      optionLabel="name"
      optionValue="cityCode"
      [(ngModel)]="selectedCityCode"
      [showClear]="false"
      appendTo="body">
    </p-dropdown>
  </div>

  <div class="col-12 flex justify-content-end">
    <button
      *ngIf="!hideCancelButton"
      pButton
      pRipple
      (click)="closeModal()"
      type="button"
      [label]="'commons.cancel' | translate"
      class="p-button-rounded p-button-text p-button-plain"></button>

    <button pButton pRipple type="button" (click)="onConfirm()" [label]="'commons.confirm' | translate"></button>
  </div>
</div>
