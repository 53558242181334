/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogInfo } from '../_interfaces/dialog-info.interface';
import { ConfirmService } from '../../../services/confirm.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  constructor(private dialogService: ConfirmService) {}

  get dialog$(): Observable<DialogInfo> {
    return this.dialogService.dialog.asObservable();
  }
}
