import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { ResponseAuth } from '../../interfaces/auth';
import { City } from '../../interfaces/collections';

const AUTH_COMPLETED_KEY = 'auth-completed';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const TENANT_KEY = 'X-Tenant-ID';
const CONTEXT_KEY = 'auth-context';
const CONTEXT_END_SUBSCRIPTION = 'ces';

// manages token and user information (username, email, roles) inside Browser’s Session Storage
@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  logged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  username?: string;

  // L'URL in modo da poter reindirizzare dopo l'accesso
  public redirectUrl?: string;

  private user: any;
  private roles: string[] = [];

  constructor(public jwtHelper: JwtHelperService, private router: Router) {
    const isLoggedIn = !!this.getToken();

    if (isLoggedIn) {
      this.user = this.getUser();
      this.roles = this.user.roles;
      if (this.roles == null || !this.isAuthCompleted()) {
        this.signOut();
        this.logged.next(false);
        return;
      }
      this.username = this.user.username;
      this.logged.next(true);
    } else {
      this.logged.next(false);
    }
  }

  signIn(data: ResponseAuth): void {
    this.saveToken(data.token);
    this.saveUser(data);
  }

  completeSignIn(): void {
    window.localStorage.setItem(AUTH_COMPLETED_KEY, 'true');
  }

  signOut(): void {
    this.logged.next(false);
    window.localStorage.clear();
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public getUser(): ResponseAuth | null {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  // Check whether the token is expired
  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (token != null) {
      return !this.jwtHelper.isTokenExpired(token); // true or false
    } else return false;
  }

  public getRoles(): string[] {
    let roles = [''];
    if (window.localStorage.getItem(TOKEN_KEY)) {
      const user = this.getUser();
      if (user) {
        roles = user.roles;
      }
    }
    return roles;
  }

  public getContext(): City | null {
    const context = window.localStorage.getItem(CONTEXT_KEY);
    if (!!context) {
      return JSON.parse(context);
    }
    return null;
  }

  public getEndSubscription(): Date {
    const endSub = window.localStorage.getItem(CONTEXT_END_SUBSCRIPTION);
    if (endSub) {
      const timeEnd = parseInt(atob(endSub), 10);
      return new Date(timeEnd);
    }
    return new Date();
  }

  public setContext(context: City, endSubscription: Date): void {
    window.localStorage.setItem(CONTEXT_END_SUBSCRIPTION,btoa(`${endSubscription.getTime()}`));
    window.localStorage.setItem(CONTEXT_KEY, JSON.stringify(context));
    window.localStorage.setItem(TENANT_KEY, context.cityCode || '');
  }

  public getHeaderTenantId(): string {
    let tenant = '';
    if (window.localStorage.getItem(TENANT_KEY) != null) {
      tenant = window.localStorage.getItem(TENANT_KEY) as string;
    }
    return tenant;
  }

  public getTenant(): string[] {
    let tenants = [''];
    if (window.localStorage.getItem(TOKEN_KEY)) {
      const user = this.getUser();
      if (user) {
        tenants = user.tenant_id;
      }
    }
    return tenants;
  }

  public isAuthCompleted(): boolean {
    return window.localStorage.getItem(AUTH_COMPLETED_KEY) === 'true';
  }

  hasPermission(permissions: string[]): boolean {
    const user = this.getUser();
    if (user == null) {
      return false;
    }
    for (const permission of permissions) {
      if (user.roles.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  // save token
  private saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
    this.logged.next(true);
  }

  // save credential of user: email, roles
  private saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
}
