/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ResponseAuth } from 'src/app/shared/interfaces/auth';
import { LicenseStatus } from 'src/app/shared/interfaces/collections';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-modal-license-status',
  templateUrl: './modal-license-status.component.html',
  styleUrls: ['./modal-license-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLicenseStatusComponent implements OnInit {
  public user?: ResponseAuth;
  public licensesStatus$ = new BehaviorSubject<LicenseStatus[]>([]);

  constructor(
    private contextService: ContextService,
    private configurationService: ConfigurationService,
    private loaderService: LoaderService,
    private ref: DynamicDialogRef
  ) {
    this.user = this.contextService.getUser() || undefined;
  }

  ngOnInit(): void {
    this.loadData();
  }

  closeModal(): void {
    this.ref.close();
  }

  private loadData(): void {
    this.loaderService.showLoader();
    this.configurationService.getUserLicensesStatus().subscribe({
      next: licensesStatus => {
        this.loaderService.hideLoader();
        this.licensesStatus$.next(licensesStatus);
      },
      error: error => {
        console.error(error);
        this.loaderService.hideLoader();
        this.licensesStatus$.next([]);
      },
    });
  }
}
