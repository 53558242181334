<div class="grid grid-nogutter mt-2">
  <div class="col-12">
    <div class="field grid">
      <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
        {{ 'commons.user' | translate }}
      </div>
      <div class="col-12 md:col-6">
        {{ user?.username }}
      </div>
    </div>
    <hr />
    <ng-container *ngFor="let license of licensesStatus$ | async; let last = last">
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'commons.city' | translate }}
        </div>
        <div class="col-12 md:col-6">
          <p>{{ license.cityName }}</p>
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'admin.configuration.map-license.typeLicense' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.type }}
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'admin.configuration.map-license.startSub' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.startSubscription | date : 'dd/MM/yyyy HH:mm' }}
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'admin.configuration.map-license.endSub' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.endSubscription | date : 'dd/MM/yyyy HH:mm' }}
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'license_status.used_maps' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.currentMaps }} {{ 'commons.of' | translate }} {{ license.numberMaxMaps }}
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'license_status.used_pois' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.currentPoi }} {{ 'commons.of' | translate }} {{ license.numberMaxPoi }}
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 font-bold pr-4 md:col-4 md:mb-0">
          {{ 'license_status.max_versions_per_maps' | translate }}
        </div>
        <div class="col-12 md:col-6">
          {{ license.numberMaxVersions }}
        </div>
      </div>
      <ng-container *ngIf="!last">
        <hr />
      </ng-container>
    </ng-container>
  </div>
</div>
