<div class="grid grid-nogutter mt-2">
  <div class="col-12">
    <form *ngIf="changePasswForm" [formGroup]="changePasswForm">
      <!-- INFO-->
      <div class="field grid">
        <label [innerHTML]="'change_password.info' | translate"></label>
      </div>
      <!-- PASSWORD ATTUALE-->
      <div class="field grid">
        <label htmlFor="password" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'commons.currentPassw' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-password
            id="password"
            formControlName="password"
            [placeholder]="'Password'"
            [feedback]="false"
            [toggleMask]="true"
            inputStyleClass="w-full p-3 mb-2"
            styleClass="w-full"></p-password>
        </div>
        <p class="text-sm text-red-500 mb-2">
          {{ errorPassword ? (errorPassword | translate : passwErrorParams) : '&nbsp;' }}
        </p>
      </div>

      <!-- NUOVA PASSWORD -->
      <div class="field grid">
        <label htmlFor="newPassword" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'commons.new_password' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-password
            id="newPassword"
            formControlName="newPassword"
            [placeholder]="'commons.new_password' | translate"
            [feedback]="false"
            [toggleMask]="true"
            inputStyleClass="w-full p-3 mb-2"
            styleClass="w-full"></p-password>
        </div>
        <p class="text-sm text-red-500 mb-2">
          {{ errorNewPassword ? (errorNewPassword | translate : passwErrorParams) : '&nbsp;' }}
        </p>
      </div>

      <!-- CONFERMA PASSWORD -->
      <div class="field grid">
        <label htmlFor="confirmPassword" class="col-12 mb-2 md:col-2 md:mb-0">
          {{ 'commons.confirm_password' | translate }}
        </label>
        <div class="col-12 md:col-10">
          <p-password
            id="confirmPassword"
            formControlName="confirmPassword"
            [placeholder]="'commons.confirm_password' | translate"
            [feedback]="false"
            [toggleMask]="true"
            inputStyleClass="w-full p-3 mb-2"
            styleClass="w-full"></p-password>
        </div>
        <p class="text-sm text-red-500 mb-2">
          {{ errorConfirmPassword ? (errorConfirmPassword | translate : passwErrorParams) : '&nbsp;' }}
        </p>
      </div>
    </form>
  </div>

  <div class="col-12 flex justify-content-end">
    <p-button pRipple [outlined]="true" severity="secondary" (click)="closeModal()">
      {{ 'commons.cancel' | translate }}
    </p-button>
    <p-button
      pRipple
      [label]="'commons.confirm' | translate"
      [disabled]="changePasswForm && !changePasswForm.valid"
      class="p-button-success ml-4"
      (click)="onConfirm()"></p-button>
  </div>
</div>
