<h4 class="mt-2" *ngIf="currentContext | async as city">{{ city.name | uppercase }}</h4>

<ul class="layout-menu">
  <!-- BACK BUTTON -->
  <ul class="layout-menu">
    <li class="layout-root-menuitem">
      <p-button
        styleClass="w-full mb-2"
        [label]="'commons.go_back' | translate"
        (click)="goBack()"
        [text]="true"
        [plain]="true"
        icon="fas fa-arrow-left"></p-button>
    </li>
  </ul>
  <ng-container *ngIf="isMapEditor$ | async">
    <ng-container *ngIf="mapInfo$.asObservable() | async as mapInfo">
      <!-- ACTIONS -->
      <li class="layout-root-menuitem">
        <div class="layout-menuitem-root-text">
          {{ 'commons.actions' | translate }}
        </div>

        <p-button
          styleClass="w-full mb-2"
          *ngIf="mapInfo.mapData?.collection?.status === 'DRAFT'"
          [label]="'map_editor.publish_title' | translate"
          (click)="sidebarEvent.emit('publishMap')"
          [disabled]="(editingMode$ | async) === true"
          icon="fas fa-upload"></p-button>

        <p-button
          styleClass="w-full"
          [label]="'manage_versions.title' | translate"
          (click)="sidebarEvent.emit('manageVersions')"
          [disabled]="(editingMode$ | async) === true"
          severity="info"
          icon="fas fa-code-branch"></p-button>
      </li>

      <!-- ENTITIES -->
      <li class="layout-root-menuitem mt-4">
        <div class="layout-menuitem-root-text">
          {{ 'commons.manage_entities' | translate }}
        </div>
        <ul>
          <!-- IDENTIFICATIVI MAPPA -->
          <li>
            <p-button
              class="p-button-collection"
              styleClass="w-full"
              (click)="sidebarEvent.emit('editInfo')"
              icon="fas fa-info"
              [label]="'commons.map_identifiers' | translate"
              [text]="true"
              [plain]="true"
              [disabled]="(editingMode$ | async) === true"></p-button>
          </li>
          <!-- POI CONTESTO -->
          <li>
            <p-button
              class="p-button-collection"
              styleClass="w-full"
              (click)="sidebarEvent.emit('city-pois')"
              icon="fas fa-map-marker-alt"
              [label]="'commons.city_pois' | translate"
              [text]="true"
              [plain]="true"
              [disabled]="(editingMode$ | async) === true"></p-button>
          </li>
          <li>
            <p-accordion>
              <!-- CATEGORIES-->
              <p-accordionTab class="p-accordion-collection">
                <ng-template pTemplate="header">
                  <div class="flex justify-content-start align-items-center w-full">
                    <i class="fas fa-monument"></i>
                    <div>{{ 'map_editor.interest_places' | translate }}</div>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <div>
                    <p-button
                      class="p-button-config"
                      styleClass="w-full"
                      iconPos="right"
                      (click)="sidebarEvent.emit('categories')"
                      icon="fas fa-cogs"
                      [label]="'commons.configure' | translate"
                      [text]="true"
                      [plain]="true"></p-button>
                  </div>
                  <div id="categories" *ngIf="hasCategories(); else noCategories">
                    <p-button
                      *ngFor="let category of mapInfo?.mapData?.categories"
                      class="p-button-config"
                      styleClass="w-full"
                      [class.selected]="(selectedCategory$ | async)?.id === category.id"
                      (click)="onSelectCategory(category)"
                      [text]="true"
                      [plain]="true">
                      <div class="flex justify-content-start align-items-center">
                        <div class="mr-2 dot" [style.background-color]="category.color"></div>
                        <span class="text-left b-description">
                          {{ category.translations | dbTranslation : 'description' }}
                        </span>
                      </div>
                    </p-button>
                  </div>
                </ng-template>
              </p-accordionTab>

              <!-- DISTANCES -->
              <p-accordionTab class="p-accordion-collection">
                <ng-template pTemplate="header">
                  <div class="flex justify-content-start align-items-center w-full">
                    <i class="fas fa-ruler"></i>
                    <div>{{ 'map_editor.travel_times' | translate }}</div>
                  </div>
                </ng-template>

                <ng-template pTemplate="content">
                  <div>
                    <p-button
                      class="p-button-config"
                      styleClass="w-full"
                      iconPos="right"
                      (click)="sidebarEvent.emit('distances')"
                      icon="fas fa-cogs"
                      [label]="'commons.configure' | translate"
                      [text]="true"
                      [plain]="true"></p-button>
                  </div>
                  <div id="distances" *ngIf="hasDistances(); else noDistances">
                    <p-button
                      *ngFor="let distance of mapInfo?.mapData?.distances"
                      class="p-button-config"
                      styleClass="w-full"
                      [class.selected]="(selectedDistance$ | async)?.id === distance.id"
                      (click)="onSelectDistance(distance)"
                      [text]="true"
                      [plain]="true">
                      <div class="flex justify-content-start align-items-center">
                        <div class="mr-2 rectangle" [style.background-color]="distance.color"></div>
                        <span class="text-left b-description">
                          <ng-container
                            *ngIf="distance.translations | dbTranslation : 'description'; else defaultLabel">
                            {{ distance.translations | dbTranslation : 'description' }}
                          </ng-container>
                          <ng-template #defaultLabel>
                            {{ 'distances.min' | translate }} {{ distance.min }} {{ 'distances.max' | translate }}
                            {{ distance.max }}
                            {{ 'commons.minutes' | translate }}
                          </ng-template>
                        </span>
                      </div>
                    </p-button>
                  </div>
                </ng-template>
              </p-accordionTab>

              <!-- ICONS -->
              <p-accordionTab class="p-accordion-collection">
                <ng-template pTemplate="header">
                  <div class="flex justify-content-start align-items-center w-full">
                    <i class="fas fa-icons"></i>
                    <div>{{ 'map_editor.other_symbols' | translate }}</div>
                  </div>
                </ng-template>

                <ng-template pTemplate="content">
                  <div>
                    <p-button
                      class="p-button-config"
                      styleClass="w-full"
                      iconPos="right"
                      (click)="sidebarEvent.emit('icons')"
                      icon="fas fa-cogs"
                      [label]="'commons.configure' | translate"
                      [text]="true"
                      [plain]="true"></p-button>
                  </div>
                  <div id="icons" *ngIf="hasIcons(); else noIcons" class="mt-2">
                    <div *ngFor="let icon of mapInfo?.mapData?.icons">
                      <div class="my-1">
                        <img width="16px" height="16px" class="mr-1" [src]="icon.image" alt="icon image" />
                        <span> {{ icon.translations | dbTranslation : 'description' }}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </li>
        </ul>
      </li>

      <!-- STATISTICS -->
      <ng-container *ngIf="showStatistics() as stats">
        <li class="layout-root-menuitem mt-4" *ngIf="stats.showStatistics">
          <div class="layout-menuitem-root-text">
            {{ 'map_editor.statistics' | translate }}
          </div>

          <div class="statistics" *ngIf="stats.showStatistics">
            <div *ngIf="stats.hasCountPois">
              {{ 'map_editor.side_statistics.map_pois' | translate }}: {{ mapInfo.statistics.countPois }}
            </div>
            <div class="info-pois with-margin" *ngIf="stats.hasPoisForCategory">
              {{ 'map_editor.side_statistics.of_which' | translate }}:
              <ng-container *ngFor="let info of mapInfo.statistics.countPoisForCategory; let last = last">
                {{ info.count }} {{ info.translations | dbTranslation : 'description' }}{{ last ? '' : ', ' }}
              </ng-container>
            </div>
            <div class="with-margin" *ngIf="stats.hasCountLinks">
              {{ 'map_editor.side_statistics.map_links' | translate }}: {{ mapInfo.statistics.countLinks }}
            </div>
            <div class="with-margin" *ngIf="stats.hasTotalDistance">
              {{ 'map_editor.side_statistics.total_distance' | translate }}:<br />{{ mapInfo.statistics.totalDistance
              }}{{ 'map_editor.side_statistics.meters' | translate }}
            </div>
            <div class="with-margin" *ngIf="stats.hasTotalTime">
              {{ 'map_editor.side_statistics.total_time' | translate }}:<br />{{ mapInfo.statistics.totalTime }}
              {{ 'map_editor.side_statistics.minutes' | translate }}
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>

<ng-template #noCategories>
  <div class="empty-side-list">
    {{ 'commons.no_categories' | translate }}
  </div>
</ng-template>

<ng-template #noDistances>
  <div class="empty-side-list">
    {{ 'commons.no_distances' | translate }}
  </div>
</ng-template>

<ng-template #noIcons>
  <div class="empty-side-list">
    {{ 'commons.no_icons' | translate }}
  </div>
</ng-template>
