<p-dropdown
  [class.w-full]="wFull"
  [options]="languageItems"
  [(ngModel)]="currentLang"
  [showClear]="false"
  (onChange)="setLanguage($event)"
  [styleClass]="'dropdown-lang' + (wFull ? ' w-full' : '')"
  appendTo="body">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <span class="fi fi-{{ languageFlagCode[currentLang] }}"></span>
    </div>
  </ng-template>
  <ng-template let-lang pTemplate="item">
    <div class="flex align-items-center gap-2">
      <span class="fi fi-{{ languageFlagCode[lang] }}"></span>&nbsp;&nbsp;
      {{ 'commons.' + lang | translate }}
      &nbsp;&nbsp;
    </div>
  </ng-template>
</p-dropdown>
