import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { map } from 'rxjs';
import { Columns } from 'src/app/shared/interfaces/admin';
import { City, CityConfiguration, PaginatedList } from 'src/app/shared/interfaces/collections';
import { MessageDto } from 'src/app/shared/interfaces/download-pdf-info';
import { CityService } from 'src/app/shared/services/city.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { MetroMinutoService } from 'src/app/shared/services/metrominuto.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';
import { AddEditCityComponent } from '../add-edit-city/add-edit-city.component';

@Component({
  selector: 'app-city-license-management',
  templateUrl: './city-license-management.component.html',
  styleUrls: ['./city-license-management.component.scss'],
})
export class CityLicenseManagementComponent implements OnInit {
  cityData: CityConfiguration[] = [];
  // table handle
  columns: Columns[] = [];
  first = 0;
  rows = 10;
  page = 0; // starts from page index 0
  loading = false; // spinner used for loading data of table
  totalRecords!: number; // total rows
  orderUserData!: string;
  translateService: any;

  constructor(
    private cityService: CityService,
    private context: ContextService,
    private configurationService: ConfigurationService,
    private metroMinutoService: MetroMinutoService,
    private translate: TranslateService,
    private notification: NotificationService,
    private confirmService: ConfirmService,
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) {
    this.columns = [
      {
        field: 'city',
        header: 'City-Code',
        subfield: 'cityCode',
      },
      {
        field: 'city',
        header: 'commons.name',
        subfield: 'name',
      },
      {
        field: 'license',
        header: 'admin.configuration.map-license.license',
        subfield: 'type',
      },
      {
        field: 'active',
        header: 'admin.configuration.users.active',
        subfield: '',
      },
      {
        field: 'startSubscription',
        header: 'admin.configuration.map-license.startSub',
        subfield: '',
      },
      {
        field: 'endSubscription',
        header: 'admin.configuration.map-license.endSub',
        subfield: '',
      },
    ];
  }

  get currentContext(): City | null {
    return this.context.currentContext.getValue();
  }

  ngOnInit(): void {
    this.getCityData();
  }

  // event lazy load
  lazyLoad(event: TableLazyLoadEvent): void {
    if (event.first != null && event.rows != null) {
      this.page = event.first / event.rows; // calculate rows
    }
    if (event.sortField != null && event.sortOrder != null) {
      this.orderUserData = event.sortOrder === 1 ? `${event.sortField},ASC` : `${event.sortField},DESC`;
    }

    this.getCityData(this.page, this.rows, this.orderUserData);
  }

  getCityData(page: number = 0, rows: number = 10, order: string = 'city.cityCode,DESC'): void {
    this.loaderService.showLoader();
    this.configurationService.getAllConfiguration(page, rows, order).subscribe({
      next: (response: PaginatedList<CityConfiguration>) => {
        this.totalRecords = response.totalElements;
        this.rows = response.size;
        this.cityData = response.content;
        this.loaderService.hideLoader();
      },
      error: err => {
        console.error(err);
        this.loaderService.hideLoader();
      },
    });
  }

  addCity(): void {
    this.openModalCity();
  }

  editCity(city: CityConfiguration): void {
    if (city == null || city.city == null || city.city.cityCode == null) {
      return;
    }
    this.openModalCity(city.city.cityCode);
  }

  licenseExpired(value: string): boolean {
    const date = moment(value).toDate();
    if (date <= new Date()) return true;
    return false;
  }

  exportExcel(): void {
    this.loaderService.showLoader();
    const headers = [
      this.translate.instant('excel.headers.refID'),
      this.translate.instant('commons.city'),
      this.translate.instant('excel.headers.licenseType'),
      this.translate.instant('commons.enabled'),
      this.translate.instant('excel.headers.startSubs'),
      this.translate.instant('excel.headers.endSubs'),
    ];

    const data: any[] = this.cityData.map(cityConfig => ({
      [headers[0]]: cityConfig.city?.cityCode,
      [headers[1]]: cityConfig.city?.name,
      [headers[2]]: cityConfig.license?.type,
      [headers[3]]: cityConfig.active ? this.translate.instant('commons.yes') : this.translate.instant('commons.no'),
      [headers[4]]: cityConfig.startSubscription,
      [headers[5]]: cityConfig.endSubscription,
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Licenze');
    XLSX.writeFile(workbook, `${this.translate.instant('commons.licenzeFileName')}.xlsx`);
    this.loaderService.hideLoader();
  }

  exportPdf(): void {
    this.loaderService.showLoader();

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
    });
    doc.setFontSize(18);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    doc.text(this.translate.instant('commons.licenzeFileName'), 30, 35);

    const head = [
      [
        '#',
        this.translate.instant('excel.headers.refID'),
        this.translate.instant('commons.city'),
        this.translate.instant('excel.headers.licenseType'),
        this.translate.instant('commons.enabled'),
        this.translate.instant('excel.headers.startSubs'),
        this.translate.instant('excel.headers.endSubs'),
      ],
    ];

    const body = this.cityData.map((cityConfig, index) => [
      index + 1,
      cityConfig.city?.cityCode,
      cityConfig.city?.name,
      cityConfig.license?.type,
      cityConfig.active ? this.translate.instant('commons.yes') : this.translate.instant('commons.no'),
      cityConfig.startSubscription,
      cityConfig.endSubscription,
    ]);

    autoTable(doc, { startY: 65, head, body });

    doc.save(`${this.translate.instant('commons.licenzeFileName')}.pdf`);

    this.loaderService.hideLoader();
  }

  async removeCity(city: CityConfiguration): Promise<void> {
    const title: string = this.translate.instant('admin.configuration.map-license.delete-city-license');
    const message: string = this.translate.instant('admin.configuration.map-license.delete-city-question', {
      name: city.city?.name,
    });
    const action = await this.confirmService.showDialog(title, message);

    if (action !== 'confirm') {
      return;
    }

    this.cityService.deleteAllFromCity(city.refid).subscribe({
      next: (response: MessageDto<boolean>) => {
        if (response.message === true) {
          this.getCityData();
          this.notification.success(
            this.translate.instant('admin.configuration.map-license.succDelContext', {
              name: city.city?.name,
            }) as string
          );
        }
        this.updateMetrominutoCities();
      },
    });
  }

  private updateMetrominutoCities(): void {
    this.metroMinutoService
      .getCities()
      .pipe(
        map(cities => {
          this.metroMinutoService.cities.next(cities);
        })
      )
      .subscribe();
  }

  private onConfirmSuccess(): void {
    this.getCityData();
    this.updateMetrominutoCities();
  }

  private openModalCity(cityCode?: string): void {
    const header = cityCode
      ? 'admin.configuration.map-license.edit-city-license'
      : 'admin.configuration.map-license.add-city-license';
    const dialogRef = this.dialogService.open(AddEditCityComponent, {
      closable: true,
      header: this.translate.instant(header),
      contentStyle: { overflow: 'auto' },
      styleClass: 'responsive-dialog',
      baseZIndex: 100,
      data: {
        cityCode,
      },
    });

    const subDialog = dialogRef.onClose.subscribe((cityConf: CityConfiguration) => {
      if (cityConf) {
        this.onConfirmSuccess();
      }
      subDialog.unsubscribe();
    });
  }
}
