import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { DialogInfo } from '../modules/dialog/_interfaces/dialog-info.interface';

@Injectable({ providedIn: 'root' })
export class ConfirmService {
  public dialog: BehaviorSubject<DialogInfo> = new BehaviorSubject({});

  constructor(private confirmationService: ConfirmationService) {}

  public async showDialog(
    title: string,
    message: string,
    hideConfirmButton = false,
    confirmLabel?: string,
    cancelLabel?: string
  ): Promise<'confirm' | 'cancel'> {
    const dialogInfo: DialogInfo = {
      title,
      bodyContent: message,
      confirmLabel,
      cancelLabel,
      hideConfirmButton,
    };

    this.dialog.next(dialogInfo);

    const result = new Promise<'confirm' | 'cancel'>((resolve) => {
      this.confirmationService.confirm({
        reject: () => {
          resolve('cancel');
        },
        accept: () => {
          resolve('confirm');
        },
      });
    });

    return result;
  }
}
