<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/img/brand/logo-full.svg" alt="Metrominuto" />
  </a>

  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
    aria-label="Apri-Chiudi menu laterale">
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="userMenu.toggle($event)"
    aria-label="Menu Utente">
    <i class="fas fa-cogs"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }">
    <div class="hidden md:block">
      <language-dropdown></language-dropdown>
    </div>

    <button
      pButton
      pRipple
      class="ml-2 p-button-text p-button-rounded p-button-secondary"
      aria-label="Menu Utente"
      icon="fas fa-cogs"
      (click)="userMenu.toggle($event)"></button>
  </div>
</div>

<app-user-menu
  #userMenu
  (showSelectContext)="openSelectContextModal()"
  (showPasswordChange)="openModalChangePassword()"
  (showLicenseStatus)="openModalLicenseStatus()"></app-user-menu>
