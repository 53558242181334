import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent {
  appVersion = environment.version.version;
  constructor(public layoutService: LayoutService) {}
}
