/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAuthGuard } from './shared/security/services/auth-guard.service';
import { canActivateAdminGuard } from './shared/security/services/admin-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home', // 'accesso/login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [canActivateAuthGuard],
  },
  {
    path: 'accesso',
    loadChildren: () =>
      import('./modules/accesso/accesso.module').then((m) => m.AccessoModule),
    canActivate: [canActivateAuthGuard],
  },
  {
    path: 'city-configuration',
    loadChildren: () =>
      import('./modules/city-configuration/city-configuration.module').then(
        (m) => m.CityConfigurationModule
      ),
    canActivate: [canActivateAuthGuard],
    data: {
      clearMapRoutes: true
    }
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./modules/public-pages/public-pages.module').then(
        (m) => m.PublicPagesModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [canActivateAuthGuard, canActivateAdminGuard],
    data: {
      clearMapRoutes: true
    }
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
