/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { map, Subscription, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public static cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: window.location.hostname,
    },
    palette: {
      popup: {
        background: '#000000ad',
        text: '#ffffff',
      },
      button: {
        background: '#6dad22',
        text: '#ffffff',
      },
    },
    theme: 'classic',
    type: 'opt-out',
  };

  private subs: Subscription = new Subscription();

  constructor(
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService
  ) {}

  public initService(): void {
    // subscribe to cookieconsent observables to react to main events
    const popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    const popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    const statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    const revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    const noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    const translateSub = this.translateService.onLangChange
      .pipe(
        switchMap(() => this.translateService.get('cookie')),
        map((data) => {
          const config = this.ccService.getConfig();
          config.content = config.content || {};
          // Override default messages with the translated ones
          config.content.header = data.header;
          config.content.message = data.message;
          config.content.dismiss = data.dismiss;
          config.content.allow = data.allow;
          config.content.deny = data.deny;
          config.content.link = data.link;
          config.content.policy = data.policy;
          config.content.href = '#/public/privacy-policy';

          this.ccService.destroy(); // remove previous cookie bar (with default messages)
          this.ccService.init(this.ccService.getConfig()); // update config with translated messages
        })
      )
      .subscribe();

    this.subs.add(popupOpenSubscription);
    this.subs.add(popupCloseSubscription);
    this.subs.add(statusChangeSubscription);
    this.subs.add(revokeChoiceSubscription);
    this.subs.add(noCookieLawSubscription);
    this.subs.add(translateSub);
  }

  public destroyService(): void {
    this.subs.unsubscribe();
  }
}
