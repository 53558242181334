import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogComponent } from './_components/dialog.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, ConfirmDialogModule, ButtonModule, TranslateModule],
  exports: [DialogComponent],
  providers: [ConfirmationService],
})
export class DialogModule {}
