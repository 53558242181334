import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { RoleEnum } from 'src/app/shared/interfaces/auth';
import { CityService } from 'src/app/shared/services/city.service';
import { CollectionService } from 'src/app/shared/services/collection.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @ViewChild(OverlayPanel) overlayPanel?: OverlayPanel;
  @Output() showSelectContext = new EventEmitter<any>();
  @Output() showPasswordChange = new EventEmitter<any>();
  @Output() showLicenseStatus = new EventEmitter<any>();

  public itemsMenu$ = new BehaviorSubject<MenuItem[]>([]);
  public displayModalChangePassw = false;
  public user = this.contextService.getUser();

  constructor(
    private contextService: ContextService,
    private router: Router,
    private userService: UserService,
    private collectionService: CollectionService,
    private poiService: POIService,
    private cityService: CityService,
    private loaderService: LoaderService
  ) {
    this.initItems();
  }

  get hasMultipleTenants(): boolean {
    return this.contextService.hasMultipleTenants();
  }

  get role(): RoleEnum {
    const roles = this.contextService.getRoles();
    if (roles.includes(RoleEnum.ROLE_MOBILITY_MANAGER)) {
      return RoleEnum.ROLE_MOBILITY_MANAGER;
    } else {
      return RoleEnum.ROLE_ADMINISTRATOR;
    }
  }

  toggle(evt: any): void {
    this.overlayPanel?.toggle(evt);
  }

  logout(): void {
    this.userService.logout().subscribe({
      next: resp => {
        if (!resp) {
          console.error('error on invalid backend logout');
        }
      },
    });
    this.contextService.signOut();
    this.router.navigate(['/accesso'], { replaceUrl: true }).finally(() => {});
  }

  exportUserData(): void {
    this.loaderService.showLoader();
    const cityCode = this.contextService.currentContext.getValue()?.cityCode || '';
    const collections = this.collectionService.getAllCollections();
    const pois = this.poiService.getAllPOIs(cityCode);
    const configuration = this.cityService.getCityConfiguration(cityCode);
    forkJoin({ collections, pois, configuration }).subscribe({
      next: responses => {
        const blob = new Blob([JSON.stringify(responses, null, 2)], { type: 'application/json' });
        const blobUrl = window.URL.createObjectURL(blob);

        const hrefElm = document.getElementById('export-link') as HTMLAnchorElement;
        hrefElm.href = blobUrl;
        hrefElm.download = 'export-context.json';
        hrefElm.click();
        window.URL.revokeObjectURL(blobUrl);
        this.loaderService.hideLoader();
      },
      error: error => {
        console.error(error);
        this.loaderService.hideLoader();
      },
    });
  }

  private initItems(): void {
    const accountItems = [
      {
        label: 'commons.changePassw',
        icon: 'fas fa-unlock-alt',
        command: (): void => {
          this.showPasswordChange.emit();
        },
      },
    ];

    const roles = this.contextService.getRoles();
    if (roles.includes(RoleEnum.ROLE_MOBILITY_MANAGER)) {
      accountItems.push({
        label: 'license_status.title',
        icon: 'fas fa-certificate',
        command: (): void => {
          this.showLicenseStatus.emit();
        },
      });
    }

    const itemsMenu: MenuItem[] = [
      {
        label: this.contextService.getUser()?.username || '',
        items: accountItems,
      },
    ];

    itemsMenu.push({
      separator: true,
    });

    if (this.hasMultipleTenants) {
      itemsMenu.push({
        items: [
          {
            label: 'commons.changeContext',
            icon: 'fas fa-globe',
            command: (): void => {
              this.showSelectContext.emit();
            },
          },
        ],
      });
    }

    itemsMenu.push({
      separator: true,
      items: [
        {
          label: 'commons.export_context',
          icon: 'fas fa-cloud-download-alt',
          command: (): void => {
            this.exportUserData();
          },
        },
      ],
    });

    itemsMenu.push({
      separator: true,
      items: [
        {
          label: 'commons.logout',
          icon: 'fas fa-sign-out-alt',
          command: (): void => {
            this.logout();
          },
        },
      ],
    });

    this.itemsMenu$.next(itemsMenu);
  }
}
