import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageDropdownComponent } from './language-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LanguageDropdownComponent],
  imports: [CommonModule, FormsModule, DropdownModule, TranslateModule],
  exports: [LanguageDropdownComponent],
})
export class LanguageDropdownModule {}
