import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpHelper } from '../helpers/http.helper';
import {
  Collection,
  PaginatedList,
  RequestPaginatedList,
} from '../interfaces/collections';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {

  constructor(public apiService: ApiService) {
  }

  /**
   * [NEW API] Recupera le Collections (Paginate)
   */
   getCollections(
    paginated: RequestPaginatedList,
    text = ''
  ): Observable<PaginatedList<Collection>> {
    let httpParams = HttpHelper.getHttpParamsFromPaginatedRequest(paginated);
    if (!!text) {
      httpParams = httpParams.set('name', text);
    }
    return this.apiService
      .get<PaginatedList<Collection>>(
        `${environment.metrominuto_service}collections`,
        {
          params: httpParams,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * [NEW API] Recupera tutte le Collections (Paginate)
   */
   getAllCollections(): Observable<Collection[]> {
    return this.apiService.get<Collection[]>(`${environment.metrominuto_service}collections/all`);
  }

  /**
   * [NEW API] Crea una nuova Collection
   *
   * @param collection
   * @returns Observable<Collection>
   */
  /* createCollection(collection: Collection): Observable<Collection> {
    return this.apiService
      .post<Collection>(
        `${environment.metrominuto_service}collections`,
        collection
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }*/

  /**
   * [NEW API] Aggiorna una Collection
   *
   * @param collection
   * @returns Observable<Collection>
   */
  updateCollection(collection: Collection): Observable<Collection> {
    if (collection == null || collection.id == null) {
      throw new Error('id collection mancante');
    }

    return this.apiService
      .put<Collection>(
        `${environment.metrominuto_service}collections/${collection.id}`,
        collection
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

}
